import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faCalendarDays, faCalendarXmark, faInfoCircle, faExclamationTriangle, faWarning } from "@fortawesome/free-solid-svg-icons";
import '../styles/BulkOrderEdit.scss';
import { t } from 'i18next';
import { Button } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { ChangeEvent, useEffect, useState, FocusEvent } from 'react';
import { getOrderToBulkUpdate, BulkDeliveryResponseUpdateModel, putOrderBulkUpdate, RequestOrderUpdateIntent } from '../services/orderService';
import { useUserStore } from '../store/user';
import { getBulkCustomerDataByIssuer, TransporterVehicleResponseModel, VeichleTypesResponse } from '../services/customerService';
import { useLocation, useNavigate } from 'react-router-dom';
import DatePicker from 'react-date-picker';
import UniModal from '../components/UniModal/UniModal';
import UniInput, { FormControlElement } from '../components/UniInput/UniInput';
import i18n from '../i18n';
import tuckLocation from '../assets/icons/ic-truck.svg';
import { v4 as uuidv4 } from 'uuid';

export type DataTable = {
    property: string,
    name: string,
    isNumber?: boolean,
    isSalesNumber?: boolean,
    isMaterialDescription?: boolean,
    isDate?: boolean,
    isTransporter?: boolean,
    isDeposit?: boolean,
    isQuantity?: boolean,
    IsVeichleType?: boolean,
    isCustomerOrderNumber?: boolean,
    isCustomerItem?: boolean,
    notShow?: boolean,
    isSmallTd?: boolean
}


const BulkOrderEdit = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [data, setData] = useState<BulkDeliveryResponseUpdateModel[]>();
    const navigate = useNavigate();
    const { selectedCustomer, loginData, darkMode } = useUserStore();
    const [msgErrorIntent, setErrorIntent] = useState<any[] | undefined>(undefined);
    const [msgErrorDate, setErrorDate] = useState<string>('');
    const location = useLocation();
    const [transporterVehicles, setTransporterVehicles] = useState<TransporterVehicleResponseModel[]>();
    const [loadingDetails, setLoadingDetails] = useState<boolean>(false);
    const [customer, setCustomer] = useState<string>('');
    const [showModalSuccess, setShowModalSuccess] = useState<boolean>(false);
    const [msgErrorQuantity, setMsgErrorQuantity] = useState<string>('');
    const [showValidatationError, setShowValidatationError] = useState<boolean>(false);
    const [errorUpdate, setErrorUpdate] = useState<boolean>(false);
    const [msgErrorsOnSap, setMsgErrorsOnSap ] = useState<boolean>(false);

    useEffect(() => {

        if (data !== undefined)
            return;

        setLoading(true);
        setCustomer(selectedCustomer.issuer);
        getOrderToBulkUpdate(selectedCustomer.issuer, getIdsFromPath())
            .then(r => {
                if (r.success) {

                    if (r.data) {
                        setData(r.data);

                        if (transporterVehicles === undefined) {
                            setLoadingDetails(true);
                            const orderIds: string[] =  r.data?.map(o => o.id);

                            getBulkCustomerDataByIssuer(i18n.language.toUpperCase(), selectedCustomer.issuer, orderIds)
                                .then(r => {
                                    if (r.data) {
                                        setTransporterVehicles([...r.data]);
                                    } else {
                                        setTransporterVehicles([]);
                                    }
                                })
                                .catch(err => console.log(err))
                                .finally(() => setLoadingDetails(false));
                        }
                    }
                }
                else {
                    setErrorIntent([{ error: t('newOrderScreen.labelOrderNotFound') }])
                }
            })
            .catch(err => setErrorIntent([{ error: t('newOrderScreen.labelOrderNotFound') }]))
            .finally(() => setLoading(false));
    }, [i18n.language]);

    useEffect(() => {
        if(customer === undefined || customer === '')return;
        if(selectedCustomer.issuer !== customer)
            navigate('/my-orders')
    },[selectedCustomer.issuer,customer])

    const getIdsFromPath = (): string[] => {

        const path = location.pathname + location.search;
        const idsPart = path.split('/bulk-edit/')[1];

        if (idsPart) {
            return idsPart.split('&').map(part => part.replace('ids=', '').trim());
        }

        return [];
    };

    const getNextUtilDate = (date: Date) => {
        const initialDate = new Date(date);
        while (initialDate.toDateString() === date.toDateString() || date.getDay() === 0 || date.getDay() === 6) {
            date.setDate(date.getDate() + 1);
        }

        return date;
    }

    const isValidDate = (e: any, selectedProduct: any, selectedIncoterm: string): boolean => {
        const date = new Date(e);
        if (!date) return false;

        if (date.toDateString() === new Date().toDateString()) {
            setErrorDate(t('newOrderScreen.returnValidEqualDate'));
            return false;
        }

        if (date < new Date()) {
            setErrorDate(t('newOrderScreen.returnValidPassedDate'));
            return false;
        }

        if ((date.getDay() === 0 || date.getDay() === 6) && !!selectedProduct?.pvc && selectedIncoterm === 'FOB') {
            setErrorDate(t('newOrderScreen.returnValidEndofWeek'));
            return false;
        }

        const dateNow = new Date();
        if ((dateNow.getHours() >= 17 || dateNow.getDay() === 0 || dateNow.getDay() === 6) && date.toDateString() === getNextUtilDate(dateNow).toDateString()) {
            setErrorDate(t('newOrderScreen.returnValidAfter17Hour'));
            return false;
        }

        const dateNow4 = new Date();
        if (selectedCustomer.enabledOrderAfterHour !== true && dateNow.getHours() >= 11 && date.toDateString() === getNextUtilDate(dateNow4).toDateString()) {
            setErrorDate(t('newOrderScreen.returnValidAfter11Hour'));
            return false;
        }

        const dateNow2 = new Date();
        const dateNowT = new Date();

        var date1 = new Date(date.toDateString());
        var date2 = new Date(dateNowT.toDateString());

        var diffTime = date1.getTime() - date2.getTime();
        var diffDays = diffTime / (1000 * 3600 * 24);

        if (selectedCustomer.enabledOrderAfterHour !== true && dateNow2.getDay() === 5 && dateNow2.getHours() >= 11 && (date.getDay() === 0 || date.getDay() === 6) && diffDays < 7) {
            setErrorDate(t('newOrderScreen.returnValidAfter11FridayHour'));
            return false;
        }

        return true;
    }

    const onChangeDate = (e: any, id: string) => {
        if (!isValidDate(e, null, '')) return;

        const truckId = data?.find(d => d.id === id)?.truckId;

        const updatedData = data?.map(d => {
            if (!truckId) {
                return d.id === id ? { ...d, date: e } : d;
            }

            return d.truckId === truckId ? { ...d, date: e } : d;
        });


        setData(updatedData);
    }

    const changeDeposit = (id: string, value: string | undefined) => {
        let delivery = data?.find(d => d.id === id);
        if (!delivery || !value) return;

        const truckId = data?.find(d => d.id === id)?.truckId

        const updatedData = data?.map(d =>{
            if(!truckId){
                return d.id === id ? { ...d, deposit: value } : d;
            }

            return d.truckId === truckId ? { ...d, deposit: value } : d;
        });

        setData(updatedData);
    }

    const onChangeOrderItem = (id: string, value: any | undefined) => {
        const updatedData = data?.map(d =>
            d.id === id ? { ...d, orderItem: value.target.value } : d
        );

        setData(updatedData);
    }

    const onChangeOrderNumber = (id: string, value: any | undefined) => {
        const updatedData = data?.map(d =>
            d.id === id ? { ...d, orderNumber: value.target.value } : d
        );

        setData(updatedData);
    }

    const onChangeVeichle = (e: any, id: string) => {
        const transpVehicle = transporterVehicles?.find(vt => vt.orderId === id);

        const vehicle = transpVehicle?.veichles.find(v => v.id === e.target.value);

        const truckId = data?.find(d => d.id === id)?.truckId;

        const updatedData = data?.map(d =>{
            if(!truckId){
                return d.id === id ? {...d, veichleType: {...d.veichleType, id: e.value,  tm: vehicle?.tm}} : d;
            }
            return d.truckId === truckId ? {...d, veichleType: {...d.veichleType, id: e.target.value, tm: vehicle?.tm}} : d;
        });

        setData(updatedData);

        checkQuantityAfterChangeVehicle(updatedData, vehicle, id);
    }

    const checkQuantityAfterChangeVehicle = (dataList?: BulkDeliveryResponseUpdateModel[] ,vehicle?: VeichleTypesResponse, id?: string ) => {

        const order = data?.find(d => d.id === id);

        let  totalOrderWeight = 0;

        if(order?.truckId)
            totalOrderWeight = sumQuantitiesByTruckId(order?.truckId ?? '', data);
        else
            totalOrderWeight = sumQuantitiesByOrderId(order?.id ?? '', data);

        if(totalOrderWeight > (vehicle?.maxValue ?? 0))
            {
                const msg= t('bulkEdit.alertOverWeight').replace('@totalOrderWeight', totalOrderWeight.toString()).replace('@vehicleMaxValue', vehicle?.maxValue.toString() ?? '-');
                setMsgErrorQuantity(msg)
                const updatedDataErrorMax = dataList?.map(d =>
                    d.truckId === order?.truckId ? { ...d, weightError: true, weightMessage: msg} : d
                );

                setData(updatedDataErrorMax);
            }else

            if(totalOrderWeight < (vehicle?.minValue ?? 0)){
                const msg= t('bulkEdit.alertUnderWeight').replace('@totalOrderWeight', totalOrderWeight.toString()).replace('@vehicleMinValue', vehicle?.minValue.toString() ?? '-');
                setMsgErrorQuantity(msg)
                const updatedDataErrorMin = dataList?.map(d =>
                    d.truckId === order?.truckId ? { ...d, weightError: true, weightMessage: msg} : d
                );
                setData(updatedDataErrorMin);
            }else{
                const updatedDataWithoutError = dataList?.map(d =>
                    d.truckId === order?.truckId ? { ...d, weightError: false} : d
                );
                 setData(updatedDataWithoutError);
             }

    }

    const onChangeTransporter = (e: any, id: string) => {
        const truckId = data?.find(d => d.id === id)?.truckId;

        const updatedData = data?.map(d =>{
            if(!truckId){
                return d.id === id ? {...d, item: {...d.item, transp: {...d.item?.transp, cod: e.target.value}}} : d;
            }

            return d.truckId === truckId ? {...d, item: {...d.item, transp: {...d.item?.transp, cod: e.target.value}}} : d;
        });

        setData(updatedData);
    }

    const setVehiclesByOrderId = (id: string, incoterm: string) => {
        if(transporterVehicles !== undefined){
            const transpVehicle = transporterVehicles?.find(vt => vt.orderId === id);

            const vehicles = transpVehicle?.veichles?.filter(v => v.incoterm === incoterm);

            return vehicles;

        }else{
            return []
        }
    }

    const getVehicleByOrder = (ordereId: string, vehicleId: string) => {
        if(transporterVehicles !== undefined){
            const transpVehicle = transporterVehicles?.find(vt => vt.orderId === ordereId);

            const vehicle = transpVehicle?.veichles?.find(v => v.id === vehicleId);

            return vehicle;
        }
    }

    const getTransporterByOrder = (ordereId: string, transpCod: string) => {
        if(transporterVehicles !== undefined){
            const transpVehicle = transporterVehicles?.find(vt => vt.orderId === ordereId);

            const transp = transpVehicle?.transporters?.find(t => t.cod === transpCod);

            return transp;
        }
    }

    const setMaterialMultipleWeight = (id: string, materialCode: string) => {
        if(!loadingDetails){
            const transpVehicle = transporterVehicles?.find(vt => vt.orderId === id);

            const materialMultipleWeight = transpVehicle?.materialMultiple?.find(m => m.materialCode === materialCode);

            return materialMultipleWeight?.multipleWeight;
        }
    }

    const setTransporterByOrderId = (id: string) => {
        if(transporterVehicles !== undefined){

            const transpVehicle = transporterVehicles?.find(vt => vt.orderId === id);

            const transporter = transpVehicle?.transporters;

            return transporter;

        }else{
            return []
        }
    }

    const onChangeQuantityItem = (e: ChangeEvent<FormControlElement>, id: string,multiple: number, truckId?: string) => {

        const order = data?.find(d => d.id === id);

        const transpVehicle = transporterVehicles?.find(vt => vt.orderId === id);

        const vehicle = transpVehicle?.veichles.find(v => v.id === order?.veichleType?.id);

         let newWeight = Number(e?.target?.value);

        const updatedData = data?.map(d =>
            d.id === id ? {...d, item: {...d.item, quantity: (newWeight) ?? 0}} : d
        );
        setData(updatedData);

        let  totalOrderWeight = 0;

        if(truckId)
            totalOrderWeight = sumQuantitiesByTruckId(truckId ?? '', updatedData);
        else
            totalOrderWeight = sumQuantitiesByOrderId(id, updatedData);

        if(totalOrderWeight > (vehicle?.maxValue ?? 0))
        {
                const msg= t('bulkEdit.alertOverWeight').replace('@totalOrderWeight', totalOrderWeight.toString()).replace('@vehicleMaxValue', vehicle?.maxValue.toString() ?? '-');
                //setMsgErrorQuantity(msg)

                const updatedDataErrorMax = updatedData?.map(d =>
                    d.id === id ? { ...d, weightError: true, weightMessage: msg} : d
                );

                setData(updatedDataErrorMax);
        }else if(totalOrderWeight < (vehicle?.minValue ?? 0)){
            const msg= t('bulkEdit.alertUnderWeight').replace('@totalOrderWeight', totalOrderWeight.toString()).replace('@vehicleMinValue', vehicle?.minValue.toString() ?? '-');
            //setMsgErrorQuantity(msg)

            const updatedDataErrorMin = updatedData?.map(d =>
                d.id === id ? { ...d, weightError: true, weightMessage: msg} : d
            );
            setData(updatedDataErrorMin);
        }else{
            const updatedDataWithoutError = updatedData?.map(d =>{
                if(truckId)
                    return d.truckId === truckId ? { ...d, weightError: false, weightMessage: ''} : d
                else
                    return d.id === id ? { ...d, weightError: false, weightMessage: ''} : d
            });
             setData(updatedDataWithoutError);
         }
    }

    function sumQuantitiesByTruckId(truckId: string, orders?: BulkDeliveryResponseUpdateModel[]): number {
        return orders?.filter(item => item.truckId === truckId && item.item?.quantity)
          .reduce((total, currentItem) => total + (currentItem.item?.quantity || 0), 0) ?? 0;
    }

    function sumQuantitiesByOrderId(orderid: string, orders?: BulkDeliveryResponseUpdateModel[]): number {
        return orders?.filter(item => item.id === orderid && item.item?.quantity)
          .reduce((total, currentItem) => total + (currentItem.item?.quantity || 0), 0) ?? 0;
    }

    const onBlurQuantityItem = (multiple: number, e: FocusEvent<FormControlElement, Element>, id: string, idItem: string, idProduct?: string, productMultiple?: any) => {
        const order = data?.find(d => d.id === id);

        const transpVehicle = transporterVehicles?.find(vt => vt.orderId === id);

        const vehicle = transpVehicle?.veichles.find(v => v.id === order?.veichleType?.id);

        let newWeight = Number(e?.target?.value);

        if(newWeight <  multiple)
            newWeight = multiple;

        const updatedData = data?.map(d =>
            d.id === id ? {...d, item: {...d.item, quantity: (newWeight) ?? 0}} : d
        );
        setData(updatedData);

        let  totalOrderWeight = 0;

        if(order?.truckId)
            totalOrderWeight = sumQuantitiesByTruckId(order?.truckId ?? '', updatedData);
        else
            totalOrderWeight = sumQuantitiesByOrderId(id, updatedData);

        if(totalOrderWeight > (vehicle?.maxValue ?? 0))
        {      // if(!order?.weightError){
                    const msg= t('bulkEdit.alertOverWeight').replace('@totalOrderWeight', totalOrderWeight.toString()).replace('@vehicleMaxValue', vehicle?.maxValue.toString() ?? '-');
                  //  setMsgErrorQuantity(msg)
                //}

                const updatedDataErrorMax = updatedData?.map(d =>
                    d.id === id ? { ...d, weightError: true, weightMessage: msg} : d
                );

                setData(updatedDataErrorMax);
            }else

        if(totalOrderWeight < (vehicle?.minValue ?? 0)){
           // if(!order?.weightError){
                const msg= t('bulkEdit.alertUnderWeight').replace('@totalOrderWeight', totalOrderWeight.toString()).replace('@vehicleMinValue', vehicle?.minValue.toString() ?? '-');
            //    setMsgErrorQuantity(msg)
            //}

            const updatedDataErrorMin = updatedData?.map(d =>
                d.id === id ? { ...d, weightError: true, weightMessage: msg} : d
            );
            setData(updatedDataErrorMin);
        }else{
            const updatedDataWithoutError = updatedData?.map(d =>{
                if(order?.truckId)
                    return d.truckId === order?.truckId ? { ...d, weightError: false, weightMessage: ''} : d
                else
                    return d.id === id ? { ...d, weightError: false} : d
            });

             setData(updatedDataWithoutError);
         }

    }

    const sendOrders = () =>{
        if (validateQuantityWithError()){
            setShowValidatationError(true);
            return;
        }

        setLoading(true);
        const payload = payloadAssembly();
        putOrderBulkUpdate(i18n.language.toUpperCase(),loginData?.email ?? '', payload)
        .then(r => {
                if(r.success){
                    if(r.data){
                        setShowModalSuccess(true);
                    }
                }
                else{
                    if(r.errors !== null){
                        const updatedData = data?.map(d => {
                            const matchingItem = r.data.find(item => item.id === d.id);
                            return matchingItem ? { ...d, sapIntegrationSuccess: true } : d;
                        });

                        setData(updatedData);
                        setMsgErrorsOnSap(true);
                    }
                    else{
                        setErrorUpdate(true);
                    }
                }
        })
        .catch(err => console.log("error"))
        .finally(() => setLoading(false));
    }

    const validateQuantityWithError = () => {

        if(data?.some(d => (d.weightError ?? false) === true))
            return true;
        else
            return false;
    }

    const payloadAssembly = ()=> {
        let payload:RequestOrderUpdateIntent[] =[];

        data?.forEach(d => {
            let order :RequestOrderUpdateIntent = {
                customerCode: d.customerCode ?? '',
                id: d.id,
                orderItem: d.orderItem ?? '',
                orderMaterial: d.orderMaterial ?? '',
                orderNumber: d.orderNumber ?? '',
                shipmentQuantity: d.item?.quantity ?? 0,
                status: 1,
                truckId: d.truckId ?? '',
                unitMeasurement: 'TO',
                veichleType: getVehicleByOrder(d.id, d.veichleType?.id ?? '')?.tm ?? '',
                veichleTypeId: d.veichleType?.id ?? '',
                veichleTypeName: getVehicleByOrder(d.id, d.veichleType?.id ?? '')?.veichleDescriptions?.[0].description ?? '',
                comment: '',
                commentItem: '',
                deposit: d.deposit ?? '-',
                incoterms: d.incoterms,
                intentItem: Number(d.intentItem),
                intentNumber: Number(d.intentNumber),
                isPVC: d.item?.pvc,
                materialCode: d.item?.materialCode,
                materialDescription: d.item?.materialDescription,
                orderIntentGroupId: d.orderIntentGroupEntityId ?? uuidv4().toString(),
                paymentCondition: d.paymentCondition,
                paymentConditionDescription: d.paymentConditionDescription,
                receiverCode: d.receiverCode,
                shipmentDate: d.date,
                transporterCode: d.item?.transp?.cod,
                transporterName: getTransporterByOrder(d.id, d.item?.transp?.cod ?? '')?.name
            }

            payload.push(order);
        })

        return payload;
    }

    const dataTableColumns: DataTable[] = [
        {
            property: 'id',
            name: `id`,
            notShow: true,
        },
        {
            property: 'salesNumber',
            name: t('myOrders.uniparOrderNumberAbr'),
            isSalesNumber: true,
            isSmallTd: true
        },
        {
            property: 'intentNumber',
            name: t('bulkEdit.portalUniparNr'),
            isNumber: true,
            isSmallTd: true
        },
        {
            property: 'materialDescription',
            name: t('newOrderScreen.labelProduct'),
            isMaterialDescription: true
        },
        {
            property: 'deliveryDate',
            name: t('bulkEdit.date'),
            isDate: true
        },
        {
            property: 'transporterCode',
            name: t('myOrders.transporter'),
            isTransporter: true,
            notShow: selectedCustomer?.country === 'AR'
        },
        {
            property: 'deposit',
            name: t('newOrderScreen.labelDeposit'),
            isDeposit: true,
            notShow: selectedCustomer?.country !== 'AR'
        },
        {
            property: 'shipmentQuantity',
            name: t('myOrders.shipmentQnt'),
            isQuantity: true,
        },
        {
            property: 'vehicleType',
            name: t('newOrderScreen.labelVehicle'),
            IsVeichleType: true
        },
        {
            property: 'customerOrderNumber',
            name: t('myOrders.customerOrderNumber'),
            isCustomerOrderNumber: true,
        },
        {
            property: 'orderItem',
            name: t('newOrderScreen.labelOrdemItem'),
            isCustomerItem: true,
        }

    ];

    return (
        <>
            <div className='bulk-edit-orders-wrapper'>
                <div className='bulk-edit-orders-header w-100'>
                    <div className='bulk-edit-orders-header-i'>
                        <FontAwesomeIcon icon={faEdit} color='#00FF3C' size='xl' />
                        <p className='w-100'>{t('bulkEdit.editOrders')}</p>
                    </div>
                </div>
                <table className='table-orders'>
                    <thead>
                        <tr className='table-header' style={{ paddingLeft: 10 }}>
                            {
                                dataTableColumns.map(header => {
                                    if (header.notShow) return;

                                    return (
                                        <td key={header.name}
                                            className={header.isSmallTd ? 'small-td' : ``}>
                                            {
                                                <>
                                                    {header.name}
                                                </>
                                            }
                                        </td>
                                    )
                                }
                                )
                            }
                        </tr>
                    </thead>
                    {
                        loading || loadingDetails ? (
                            <Skeleton
                                width={'100%'}
                                height={400}
                                duration={1}
                                highlightColor={ darkMode ? '#6c6b6b' : '#f5f5f5'}
                                baseColor={darkMode? '#021717' : '#ebebeb'}/>
                        ) :
                            (
                                error ?
                                    (<p className='w-100 text-center p-2'>{t('customerScreen.searchNotFound')}</p>) :
                                    <tbody>
                                        {
                                            data?.map((item: BulkDeliveryResponseUpdateModel, idx: number) => (

                                                <tr className='item-header' key={item.id}>

                                                    {
                                                        dataTableColumns.map(header => {

                                                            if (header.notShow) {
                                                                return <></>;
                                                            }

                                                            if (header.isSalesNumber) {
                                                                return (
                                                                    <td className='small-td'>
                                                                        {item.salesNumber ?? '-'}
                                                                    </td>
                                                                )
                                                            }

                                                            if (header.isNumber) {
                                                                return (
                                                                    <td className='small-td'>
                                                                        {item.intentNumber}
                                                                    </td>
                                                                )
                                                            }

                                                            if (header.isMaterialDescription) {
                                                                const materialDescription = item?.item?.materialDescription ?? '';
                                                                return (
                                                                    <td>
                                                                        {materialDescription}
                                                                    </td>
                                                                )
                                                            }

                                                            if (header.isDate) {
                                                                return (
                                                                    <td>
                                                                        <div className= {item?.sapIntegrationSuccess ? 'input date integration-success-data' : 'input date' }style={{ width: '150px' }}>
                                                                            <DatePicker
                                                                                id='date-picker'
                                                                                clearIcon={null}
                                                                                calendarIcon={<FontAwesomeIcon icon={faCalendarDays} />}
                                                                                dayPlaceholder=''
                                                                                monthPlaceholder=''
                                                                                yearPlaceholder=''
                                                                                onChange={e => onChangeDate(e, item?.id ?? '')}
                                                                                value={item.date}
                                                                                closeCalendar
                                                                                minDetail='year'
                                                                                disabled ={item.sapIntegrationSuccess}
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                )
                                                            }

                                                            if (header.isTransporter) {
                                                                return (
                                                                    <td className={item?.sapIntegrationSuccess ? 'integration-success' : ''}>
                                                                        <UniInput
                                                                            id='transporter'
                                                                            isSelect
                                                                            disabled ={item.sapIntegrationSuccess}
                                                                            loading ={loadingDetails}
                                                                            type='select'
                                                                            value={item?.item?.transp?.cod}
                                                                            defaultValue={'-'}
                                                                            onChange={(e) => onChangeTransporter(e, item.id)}
                                                                        >
                                                                            {
                                                                                setTransporterByOrderId(item.id)?.map((t, idx) => (
                                                                                    <option key={idx} value={t?.cod}>{t?.name ?? 'a'}</option>
                                                                                ))
                                                                            }
                                                                        </UniInput>
                                                                    </td>
                                                                )
                                                            }

                                                            if (header.isDeposit) {
                                                                return (
                                                                    <td className={item?.sapIntegrationSuccess ? 'integration-success' : ''}>
                                                                        <UniInput
                                                                            id='deposit'
                                                                            isSelect
                                                                            type='select'
                                                                            value={item.deposit}
                                                                            placeholder={t('newOrderScreen.labelDeposit')}
                                                                            defaultValue={'-'}
                                                                            onChange={(e) => { changeDeposit(item.id, e.target.value) }}
                                                                            disabled ={item.sapIntegrationSuccess}
                                                                        >
                                                                            <option value='BAHIA BLANCA'>Bahia Blanca</option>
                                                                            <option value='EZEIZA'>Ezeiza</option>
                                                                        </UniInput>
                                                                    </td>
                                                                )
                                                            }

                                                            if (header.isQuantity) {
                                                                return (
                                                                    <td className={item?.weightError ? 'error weight-alert' : item?.sapIntegrationSuccess ? 'integration-success weight-alert' : 'weight-alert'}>
                                                                           <UniInput
                                                                                type={'number'}
                                                                                id='qtd-item'
                                                                                htmlLabel={
                                                                                    <FontAwesomeIcon
                                                                                        icon={faInfoCircle}
                                                                                        style={{ marginLeft: 5, color: item?.quantity === 0 ? '#EA2027' : '#ed9306', height: 14, cursor: 'help' }}
                                                                            />
                                                                                }
                                                                                disabled ={item.sapIntegrationSuccess}
                                                                                loading = {loadingDetails}
                                                                                defaultValue='0'
                                                                                placeholder={t('newOrderScreen.labelQtyInTruck')}
                                                                                value={item?.item?.quantity === 0 ? '' : item?.item?.quantity}
                                                                                onChangeInput={(e) => onChangeQuantityItem(e, item?.id, setMaterialMultipleWeight(item?.id, item?.item?.materialCode ?? '') ?? 1,  item?.truckId)}

                                                                                onBlurInput={(e) => onBlurQuantityItem(setMaterialMultipleWeight(item?.id, item?.item?.materialCode ?? '') ?? 1, e, item?.id,'','','')}
                                                                                step={ setMaterialMultipleWeight(item?.id, item?.item?.materialCode ?? '') ?? 1}
                                                                            />
                                                                             <FontAwesomeIcon icon={faInfoCircle} style={{ marginLeft: 5, color: (item?.weightError) ? '#EA2027' : 'transparent', height: 14, cursor: (item?.weightError) ?'help' : '' }} title={ item?.weightMessage}  ></FontAwesomeIcon>
                                                                    </td>
                                                                )
                                                            }

                                                            if (header.IsVeichleType) {
                                                                return (
                                                                    <td className={item?.sapIntegrationSuccess ? 'integration-success' : ''}>
                                                                        <UniInput
                                                                            id='vehicle'
                                                                            disabled ={true}
                                                                            loading ={loadingDetails}
                                                                            isSelect
                                                                            type='select'
                                                                            value={item?.veichleType?.id}
                                                                            placeholder={t('newOrderScreen.labelVehicleType')}
                                                                            defaultValue={'-'}
                                                                            onChange={(e) => onChangeVeichle(e, item.id)}
                                                                        >
                                                                            {
                                                                                setVehiclesByOrderId(item.id, item?.incoterms ?? '')?.map((v, idx) => (
                                                                                    <option key={idx} value={v?.id}>{v?.veichleDescriptions?.[0]?.description ?? idx}</option>
                                                                                ))
                                                                            }
                                                                        </UniInput>
                                                                    </td>
                                                                )
                                                            }

                                                            if (header.isCustomerOrderNumber) {
                                                                return (
                                                                    <td className={item?.sapIntegrationSuccess ? 'integration-success' : ''}>
                                                                        <UniInput
                                                                            disabled ={item.sapIntegrationSuccess}
                                                                            id='NumPed'
                                                                            type='text'
                                                                            placeholder={'Número pedido'}
                                                                            value={item?.orderNumber}
                                                                            onChangeInput={(e) => onChangeOrderNumber(item.id, e)}
                                                                        />
                                                                    </td>
                                                                )
                                                            }

                                                            if (header.isCustomerItem) {
                                                                return (
                                                                    <td className={item?.sapIntegrationSuccess ? 'integration-success' : ''}>
                                                                        <UniInput
                                                                            disabled ={item.sapIntegrationSuccess}
                                                                            id='itemPed'
                                                                            type='text'
                                                                            placeholder={'Item Pedido'}
                                                                            value={item?.orderItem}
                                                                            onChangeInput={(e) => onChangeOrderItem(item.id, e)}
                                                                        />
                                                                    </td>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                            )
                    }
                </table>

                <div className='button-wrapper w-100'>
                    <Button className='back-btn'
                        onClick={() => navigate('/my-orders')}
                        disabled={loading || loadingDetails}
                        variant='outline'
                    >
                        {t('newOrderScreen.labelBack')}
                    </Button>
                    <Button
                        disabled={loading || loadingDetails}
                        onClick={() => sendOrders()}
                    >
                        {t('newOrderScreen.labelSaveChanges')}
                    </Button>
                </div>
            </div>

            <UniModal
                icon={faCalendarXmark}
                isFontAwsome
                title={t('newOrderScreen.labelModalHeaderTextValidDate')}
                message=''
                iconColor='#EA2027'
                show={msgErrorDate !== ''}
                onHide={() => {
                    setErrorDate('');
                }}
                iconWidth={100}
                iconHeight={110}
            >
                <div className='modal-body-resume'>
                    <p>{msgErrorDate}</p>
                    <Button onClick={() => setErrorDate('')}>{t('newOrderScreen.labelModalFileImportButton')}</Button>
                </div>
            </UniModal>

            <UniModal
                icon={tuckLocation}
                title={t('bulkEdit.editSuccess')}
                message=''
                show={showModalSuccess}
                onHide={() => {
                    setShowModalSuccess(false);
                    navigate('/my-orders');
                }}
                iconWidth={100}
                iconHeight={100}
            >
                <div className='modal-body-resume'>
                    <Button onClick={() => navigate('/my-orders')}>{t('newOrderScreen.labelForMyOrders')}</Button>
                </div>
            </UniModal>

            <UniModal
                icon={faExclamationTriangle}
                isFontAwsome
                title={t('bulkEdit.invalidQuantity')}
                message=''
                iconColor='#EA2027'
                show={msgErrorQuantity !== ''}
                onHide={() => {
                    setMsgErrorQuantity('');
                }}
                iconWidth={100}
                iconHeight={110}
            >
                <div className='modal-body-resume'>
                    <p>{msgErrorQuantity}</p>
                    <Button onClick={() => setMsgErrorQuantity('')}>{t('newOrderScreen.labelModalFileImportButton')}</Button>
                </div>
            </UniModal>

            <UniModal
                icon={faExclamationTriangle}
                isFontAwsome
                title={t('bulkEdit.checkRedFields')}
                message=''
                iconColor='#EA2027'
                show={showValidatationError}
                onHide={() => {
                    setShowValidatationError(false);
                }}
                iconWidth={100}
                iconHeight={110}
            >
                <div className='modal-body-resume'>
                    <p>{t('bulkEdit.validationAlert')}</p>
                    <Button onClick={() => setShowValidatationError(false)}>{t('newOrderScreen.labelModalFileImportButton')}</Button>
                </div>
            </UniModal>

            <UniModal
                icon={faExclamationTriangle}
                isFontAwsome
                title={t('defaultErrorTitle')}
                message=''
                iconColor='#EA2027'
                show={errorUpdate}
                onHide={() => {
                    setErrorUpdate(false);
                }}
                iconWidth={100}
                iconHeight={110}
            >
                <div className='modal-body-resume' key='1'>
                    <div>
                        {
                         t('defaultErrorBody')
                        }
                    </div>
                    <Button onClick={() => setErrorUpdate(false)}>{t('newOrderScreen.labelModalFileImportButton')}</Button>
                </div>
            </UniModal>

            <UniModal
                icon={faWarning}
                isFontAwsome
                title= {t('newOrderScreen.titleErrorOnSap')}
                message=''
                iconColor='#ECEF4E'
                show={msgErrorsOnSap}
                onHide={() => {
                    setMsgErrorsOnSap(false);
                }}
                iconWidth={50}
                iconHeight={60}
            >
                <div className='modal-body-resume'>
                    <p>{t('newOrderScreen.editErrorOnSapLineOne')}</p>
                    <p>{t('newOrderScreen.errorOnSapLineTwo')}</p>
                    <>
                            <Button style={{ backgroundColor: '#FF0000' }} variant='primary' onClick={() => navigate('/my-orders')}>{t('myOrders.no')}</Button>&nbsp;
                            <Button variant='primary' onClick={() => setMsgErrorsOnSap(false)}>{t('myOrders.yes')}</Button>
                        </>
                </div>
            </UniModal>
        </>
    )
}
export default BulkOrderEdit;