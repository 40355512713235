export default {
  en: {
    translation: {
      welcome: 'Welcome to the Unipar Customer Portal',
      newOrder: 'New Order',
      myOrder: 'My Orders',
      financial: 'Financial',
      logout: 'Logout',
      drakMode: 'Drak Mode',
      home: 'Home',
      links: 'Links',
      users: 'Users',
      interval: 'Interval',
      intervalAll: 'All',
      createdAt: 'Created At',
      search: 'Search',
      noResults: 'No results were found',
      attachFile: 'Attach file',
      addFile: 'Add file',
      permittedFiles: 'Permitted Files: ',
      attachments: 'Attachments',
      fileRemoveModalTitle: 'Do you want to remove file?',
      close: 'Close',
      confirm: 'Confirm',
      back: 'Back',
      send: 'Send',
      fieldErrorMandatoryMessage: 'Field is mandatory',
      errorSearchMessage: 'Error getting data',
      errorRemoveMessage: 'Error removing data',
      login: {
        title: 'Unipar Customer',
        subtitle: 'Our purpose is to be trustworthy in all our relations',
        login: 'Login',
        forgotPass: 'Forgot my password',
        register: 'First access',
        pass: 'Password',
        passValidation: 'Please, type your password',
        emailValidation: 'Please, type your e-mail',
        robotBtn: 'I am not a robot',
      },
      homeWidgets: {
        newOrderTitle: 'Place a new order',
        newOrderSubTitle: 'Request and accompany.',
        newOrderBtn: 'Place new order',
        graphTitle: '- Status x Tons (month)',
        graphTitleOrder: 'Orders',
        graphTotal: 'Total (Tons)',
        graphBilled: 'Billed',
        notfoundOrders: 'We could not find any orders for you in the current month.',
        notfoundOrdersProgramming: 'We could not find any orders for you in the current month.',
        programTitle: 'Programming',
        myOrderWidgetTitle: 'My Orders',
        tonMonthTitle: 'Tons billed per month',
        tdOrderNumber: 'Order Number',
        tdShipmentDate: 'LOAD DATE',
        tdDeliveryDate: "Delivery Date",
        tdShipmentQtd: 'QTD.',
        tdStatus: 'STATUS',
        tdActions: 'ACTIONS',
        Jan: 'Jan',
        Fev: 'Feb',
        Mar: 'Mar',
        Abr: 'Apr',
        Mai: 'May',
        Jun: 'Jun',
        Jul: 'Jul',
        Ago: 'Aug',
        Set: 'Set',
        Out: 'Oct',
        Nov: 'Nov',
        Dez: 'Dec',
        analise: 'Unipar Analysis',
        pendenteCliente: 'Pending Customer',
        confirmado: 'Scheduled Order',
        chegou: 'Arrived in court',
        entrou: 'Entrance factory',
        carregando: 'Loading',
        faturado: 'Invoiced',
        cancelado: 'Canceled',
        recusado: 'Refused',
        analiseAlteracao: 'Change analysis',
        analiseCancelado: 'Cancellation analysis',
        statusTonAnalise: 'In Unipar analysis',
        statusTonPendente: 'Pending Customer',
        statusTonChegou: 'Arrived in the court',
        statusTonEntrou: 'Entrance factory',
        statusTonFaturado: 'Invoiced',
        showAllMyOrders: 'Show All',
        modalErrorRebuyTitle: 'Unfortunately, it is not possible to repurchase this product',
        modalErrorRebuyMsg: 'But you can place a new orde',
        modalErrorRebuyBtn: 'Place new order',
        modalErrorCancelTitle: 'Order Cancellation',
        modalErrorCancelMsg: 'Are you sure you want to cancel the order?',
        modalErrorCancelBtnY: 'Yes',
        modalErrorCancelBtnN: 'No',
        modalErrorCancelBtnClose: 'Close',
        modalErrorCancelFeedbackOk: 'Order canceled successfully!',
        modalErrorCancelFeedbackErr: 'Unable to cancel the order.',
      },
      myOrders: {
        newOrder: 'New Order',
        myOrders: 'My Orders',
        searchFor: 'Search for',
        search: 'Search',
        interval: 'Interval',
        intervalAll: 'All',
        intervalCurrentMonth: 'Current Month',
        intervalNextMonth: 'Next Month',
        intervalLastMonth: 'Last Month',
        intervalLastSixty: 'Last 60 days',
        intervalLastNinety: 'Last 90 days',
        product: 'Product',
        uniparAnalysis: 'Unipar Analysis',
        customerPendency: 'Customer Pendency',
        orderConfirmed: 'Order Confirmed',
        arrived: 'Arrived in the courtyard',
        entered: 'Entered the courtyard',
        loaded: 'Loaded',
        invoiced: 'Invoiced',
        refused: 'Refused',
        changeAnalysis: 'Change analysis',
        cancellationAnalysis: 'Cancellation analysis',
        cancelOrder: 'Cancel orders',
        exportOrders: 'Export orders',
        noResults: 'No results were found',
        uniparOrderNumber: 'Unipar Order Number',
        materialNumber: 'Material Code',
        paymentConditions: 'Payment conditions',
        orderItem: 'Order Item',
        deposit: 'Deposit',
        totalValue: 'Total Value',
        portalOrderNumber: 'Portal Order Number',
        typeOfShipping: 'Type of shipping',
        actions: 'actions',
        rebuy: 'Rebuy',
        edit: 'Edit',
        cancel: 'Cancel',
        approveRefuse: 'Approve/Refuse',
        changeReview: 'Review Changes',
        checkDetails: 'Check Details',
        orderDetails: 'Order details',
        unfortunately: 'Unfortunately, it is not possible to repurchase this product.',
        doNewOrder: 'But you can do a new order',
        cancelSelectedOrder: 'Are you sure you want to cancel the selected order(s)?',
        yes: 'Yes',
        no: 'No',
        close: 'close',
        unableDownload: 'Unable to download Excel, please try again!',
        customerOrderNumber: 'Customer Order Nr',
        uniparOrderNumberAbr: 'Unipar Order Nr',
        transporter: 'Transporter',
        shipmentDate: 'Shipment Date',
        deliveryDate: 'Delivery Date',
        shipmentQnt: 'Quantity',
        materialDescription: 'Material description',
        reasonCancelation: 'Canceled by customer',
        orderCancelSuccess: 'Order(s) successfully canceled!',
        orderErrorCancelSuccess: 'Selected order(s) cannot be canceled.',
        select: 'Select',
        aproveChanges: 'Approve changes',
        labelDateIn: 'Date in',
        labelDateUntil: 'Date until',
        labelInvalidDate: 'Invalid date',
        labelMsgErrorDateOut: 'Please select an end date greater than the start date!',
        labelMsgErrorDateInitial: 'Please select a start date!',
        observations: 'Observations',
        editOrders: 'Edit Orders',
        alertSelectOneOrder: 'Select at least one order!'
      },
      myOrdersDetails: {
        orderNumber: 'ORDER Nº',
        orderInternalNumber: 'ORDER INTERNAL Nº',
        deliveryAt: 'DELIVERY/CAR. IN',
        center: 'CENTER',
        transporter: 'TRANSPORTER',
        quantity: 'QUANTITY',
        vehicle: 'VEHICLE',
        receiver: 'RECEIVER',
        date: 'CREATED IN',
        shipping: 'SHIPPING',
        plate: 'PLATE',
      },
      pagination: {
        itensPerPage: 'Itens per page'
      },
      forgotPass: {
        msgEmail: 'Enter your email to reset your password',
        verifyEmail: 'Check email',
        back: 'Back',
        validationEmail: 'Please, type your e-mail',
        msgCode: 'We have sent a code to the email address provided, please enter it in the field below',
        inputCode: 'Code',
        verifyCode: 'Verify codeo',
        backLogin: 'Back to login',
        validationCode: 'Please enter the code',
        msgNewPass: 'Set and confirm your new password',
        inputNewPass: 'New Password',
        inputConfirmNewPass: 'Confirm new password',
        validationNewPass: 'Please enter your password',
        validationConfirmPass: 'Please confirm your new password',
        verifyNewPass: 'Change Password',
        validationMsg: 'Your password must contain the following requirements:',
        validationLength: '- Have at least 8 digits',
        validationNumber: '- Have at least 1 number',
        validationLower: '- Have a uppercase letter',
        validationUpper: '- Have a lowercase letter',
        validationSpecial: '- Have at least 1 special character',
        validationMatch: '- Password and password confirmation same',
        notReceiveCode: 'Did not receive the code',
        msgResend: 'You can request the resend of the code at ',
        seconds: 'seconds',
        erroEmail: 'Wrongly typed email',
        errorCode: 'Invalid or expired code',
        errorPass: 'Unable to change your password',
        resendEmail: 'Resend email',
        tooltipResend: 'Check your spam or junk folder.',
        erroEmailContato: 'Please contact Customer Service:',
        erroEmailContatoPVCBrasil: 'PVC Brazil - atendimentopvc@unipar.com',
        erroEmailContatoQuimicoBrasil: 'Chemicals Brazil - servicoaocliente@unipar.com',
        erroEmailContatoArgentina: 'PVC and Chemicals Argentina - atencionalcliente@unipar.com'
      },
      firstAccess: {
        msgEmail: 'Enter your email to create your access',
        modalTitle: 'All right!',
        modalBody: 'Your access has been created, now just login.',
        modalBtn: 'Go to login'
      },
      utilLinksScreen:
      {
        labelLinkDescCorporateSite: 'Corporate WebSite',
        labelLinkDescChemicalProducts: 'Chemical Products',
        labelLinkDescPVC: 'PVC Products',
        labelLinkDescSGI: 'Certify SGI',
        labelCountry: 'Brazil',
        labelLinkUtil: 'Useful Links'
      },
      financialScreen:
      {
        labelSelect: 'Select',
        labelInvoice: 'NFe',
        labelEmissionDate: 'Emission',
        labelExpiredDate: 'Expired',
        labelSalesNumber: 'Doc. Sales',
        labelTotalValue: 'Total Value',
        labelStatus: 'Status',
        labelAction: 'Documents',
        labelDocumentsFinancial: 'Financials/Documents',
        labelNfNumber: 'NF Number',
        labelSearch: 'Search',
        labelSearchPlaceholder: 'Search by NF or Customer Order',
        labelPeriod: 'Type of Period',
        labelForEmission: 'By Emission',
        labelForExpired: 'By Expire',
        labelDateIn: 'Date in',
        labelDateUntil: 'Date until',
        labelMsgErrorDateOut: 'Please select an end date greater than the start date!',
        labelMsgErrorDateInitial: 'Please select a start date!',
        labelOverdue: 'Overdue',
        labelPaidOut: 'Paid Out',
        labelPending: 'Pending',
        labelAll: 'All',
        labelExportExcel: 'Export data for excel',
        labelExportExcelBtn: 'Export Excel',
        labelExportXml: 'Download XML',
        labelExportDanfe: 'Download DANFE',
        labelExportInvoice: 'Download Invoice',
        labelExportRemito: 'Download Remito',
        labelExportCertificate: 'Download Certificate',
        labelNotFound: 'No results were found',
        labelGenerateBankBill: 'Generate Bank Bill',
        labelDownloadXML: 'Download XML',
        labelXmlError: 'XML not yet available, please try again later!',
        labelDownloadDANFE: 'Download DANFE',
        labelDANFEError: 'DANFE not yet available, please try again later!',
        labelDownloadCertify: 'Download Certify',
        labelCertifyError: 'Certify not yet available, please try again later!',
        labelRemitoError: 'Remito not yet available, please try again later!',
        labelInvalidDate: 'Invalid date',
        labelErrorDownloadComplete: 'Unable to complete download',
        labelDownloadPending: 'Downloading...',
        labelCustomerOrder: 'Customer Order',
        labelQuantity: 'Quantity (Ton)',
      },
      newOrderScreen: {
        rulesTitle: 'Rules for inclusions, changes and cancellations of orders:',
        rule1: 'Same-day additions and changes, please contact Customer Service.',
        rule2: 'Inclusions and changes for the next day must be sent by 11:00 am the day before the upload, Monday through Friday.',
        rule3: 'Requests submitted outside of administrative hours (after 5:00 pm), weekends and holidays, will be reviewed on the next business day.',
        rule4: 'In urgent cases, contact Customer Service.',
        newOrderTitle: 'New order',
        labelProductInformation: 'Product information',
        labelProduct: 'Product',
        labelTotalVolume: 'TOTAL VOLUME',
        labelOrder: 'ORDERS',
        labelSelect: 'Select',
        labelOrderDetail: 'Order details',
        labelDeliveryPlace: 'Delivery place *',
        labelFreight: 'Freight *',
        labelPaymentConditions: 'Payment conditions *',
        labelBulkOrder: 'Generate bulk orders',
        labelBulkOrderEditCIF: 'Import bulk deliveries',
        labelBulkOrderEditFOB: 'Import bulk upload',
        labelChangesInformationCIF: '*Any changed information above may cause changes to deliveries already added',
        labelChangesInformationFOB: '*Any changed information above may cause changes to shipments already added',
        labelRequiredFields: '* Required fields',
        labelRequiredFieldsValidate: 'Check the required fields',
        labelSendOrder: 'Send Order',
        labelAdd: 'Add',
        labelLoad: 'load',
        labelDelivery: 'delivery',
        labelLoadResume: 'Load in',
        labelDeliveryResume: 'Delivery in',
        labelImportFileOrderIntent: 'Import orders in bulk',
        labelCancel: 'Cancel',
        labelImport: 'Import',
        labelChooseFile: 'Choose File',
        labelFileSelected: 'File selected',
        labelTextModelPart1: 'You can import multiple load dates using a spreadsheet. Download the template',
        labelTextModelPart2: 'or',
        labelTextModelPart3: 'to ship correctly',
        labelCIFFile: 'Unipar delivery (CIF)',
        labelFOBFile: 'Customer withdraws (FOB)',
        labelSFRFile: 'Free Shipping (SFR)',
        labelIncotermDateFOB: 'Load Date',
        labelIncotermDateCIF: 'Delivery Date',
        labelIncotermDateEditCIF: 'Delivery Date',
        labelCPTFile: 'Unipar delivery (CPT)',
        labelFCAFile: 'Customer withdraws (FCA)',
        labelTextModelARPart1: 'You can import multiple load dates using a spreadsheet. Download the ',
        labelTextModelARPart2: 'template',
        labelTextModelARPart3: 'to ship correctly',
        labelVehicleType: 'Vehicle Type',
        labelDeposit: 'Deposit',
        labelVehiclesQty: 'Vehicles Qty',
        labelTotalQty: 'Total Qty',
        labelDeliveryText: 'Delivery',
        labelQuantityWeight: 'Quantity (ton) *',
        labelVehicle: 'Vehicle',
        labelDelete: 'Delete',
        labelShippingCompany: 'Shipping Company',
        labelOrderNumber: 'Your order number',
        labelMaterialNumber: 'Material number',
        labelOrdemItem: 'Order Item',
        labelModalHeaderTextValidDate: 'Invalid selected date!',
        labelModalHeaderTextValidQuantity: 'Invalid selected quantity!',
        labelInformationDANFE: 'Field not mandatory, only enter this information in DANFE if necessary',
        returnValidEqualDate: 'It is not possible to schedule deliveries for the same day as the request!',
        returnValidPassedDate: 'It is not possible to schedule deliveries for days past!',
        labelModalHeaderFileImport: 'Import File',
        labelModalFileImportButton: 'I understood',
        returnValidEndofWeek: 'It is not possible to schedule shipments of PVC materials on weekends!',
        returnValidAfter17Hour: 'Requests sent after 5pm or on weekends for delivery on the next business day will be analyzed on the next business day!',
        returnValidAfter11Hour: 'It is not possible to schedule shipments for the next business day after 11 am, in this case, please contact Unipar.',
        returnValidAfter11FridayHour: 'It is not possible to schedule deliveries for the weekend after 11am on Friday!',
        returnErrorSelectedProduct: 'Choose one product!',
        returnErrorSelectedMethodDelivery: 'Select a delivery method!',
        returnOKImportFile: 'File imported successfully!',
        returnErrorExtensionFiles: 'It is only possible to import files with the extension .csv, .xls and .xlsx',
        labelSelectedReceiver: 'Please select a recipient',
        labelSelectedIncoterm: 'Please select a shipping type',
        labelPaymentCondition: 'Please select a payment condition',
        labelMessageDeliveryDate: 'Please add a date for your delivery',
        labelReturnOkOrder: 'Request sent successfully!',
        labelComingSoon: 'We will return soon',
        labelAccessArea: 'To follow your companion, access the area',
        labelMyOrders: 'my orders',
        labelForMyOrders: 'Go to my orders',
        labelErrorMessageOrder: 'An error occurred while requesting the order',
        labelImportFile: 'Order Import',
        labelErrorImportFile: 'The following errors occurred when importing the file:',
        labelMultipleQuantityError: 'The quantity reported is not a multiple of the product',
        labelMultipleQuantityErrorDetail: 'The quantity [informedQuantity] is not a multiple of the [multipleWeight]',
        labelQtyInTruck: 'Qty. in the truck',
        labelOrderNotFound: 'No pudimos ver el pedido seleccionado, inténtelo nuevamente.',
        labelBack: 'Back',
        labelErrorFindOrder: 'An error occurred when querying the order',
        labelFileModel: 'Template File',
        labelOrderItem: 'Orders Items',
        labelQtyResume: 'Quantity',
        labelAddObservation: 'Add observations',
        labelEditOrder: 'Edit Order',
        labelFinalizeOrder: 'Finalize Order',
        labelErrorDateOutOfLimit: 'It is not possible to edit the order, as it has already passed 11 am and the order is for the next business day.',
        labelErrorDatePassed: 'It is not possible to edit the order, as its date is past.',
        labelErrorDateEqual: 'It is not possible to edit the order, as the order date is the same as today.',
        labelSaveChanges: 'Save changes',
        labelConfirmAlterOrder: 'Change request sent successfully!',
        labelErrorAlterOrder: 'Ocorreu um erro ao alterar o pedido',
        labelObservation: 'Observations',
        titleErrorOnSap: 'We are unable to process some orders.',
        errorOnSapLineOne: 'Unfortunately, we were unable to process some of your orders. Would you like to try purchasing the unprocessed orders again?',
        errorOnSapLineTwo: 'If the error persists, please contact the support team.',
        materialsWithError: 'Unable to change materials:',
        editErrorOnSapLineOne: 'Unfortunately, we were unable to process some of your requests. Would you like to try updating the unprocessed requests again?'

      },
      notifications: 'Notifications',
      footer: {
        labelPrivacyPolicies: "Privacy Policies"
      },
      aproveChangesScreen: {
        tdShipmentDate: 'LOAD. IN',
        tdShipmentQuantity: 'Quantity',
        tdVeichleTypeName: 'Vehicle name',
        tdTransporterName: 'Carrier Name',
        tdOrderNumber: 'Order Number',
        tdMaterialDescription: 'Order item',
        tdSelect: 'Select',
        labelAproveOrder: 'Approve change orders',
        labelOrderNumber: 'Your order number',
        labelMaterialCode: 'Material Number',
        labelPaymentConditions: 'Payment conditions',
        labelMaterialDescription: 'Material description',
        labelPendenciesAprove: 'Pending Approvals',
        labelAllAprove: 'Approve All',
        labelNotFound: 'No results were found',
        labelDelivery: 'Delivery',
        labelQuantity: 'Quantity',
        labelVehicle: 'Vehicle',
        labelTransporter: 'Transporter',
        labelOrderItem: 'Order Item',
        labelModify: 'Modify in',
        labelObs: 'Observation',
        labelBack: 'Back',
        labelSave: 'Save',
        labelMsgSave: 'Change saved successfully!',
        labelComingSoon: 'We will return soon',
        labelAccessArea: 'To follow, access the area',
        labelMyOrders: 'My Orders',
        labelGotoMyOrders: 'Go to my orders',
        labelErrorSave: 'An error occurred while saving changes',
        labelOK: 'I understood',
        labelTryAgain: 'Please try again!'
      },
      customerSurvey: {
        step1Title: 'Your opinion is very important to improve our Portal, after all it was made for you!',
        step2Title: 'Your opinion is very important to improve our Portal, after all it was made for you!',
        step3Title: 'Your opinion is very important to improve our Portal, after all it was made for you!',
        step4Title: 'Thanks for your evaluation!',
        step1Description: 'On a scale of 1 to 5, how would you rate your experience using our platform?',
        step2Description: 'On a scale of 1 to 5, how much would you recommend our Portal to a friend or colleague?',
        step3Description: 'Do you want to leave us any comments or suggestions to help us improve our platform?',
        step4Description: 'Your evaluation help us understand how we can improve our platform. Your feedback is invaluable to us!',
        step3Comments: 'Write here any comments',
        terrible: 'Terrible',
        bad: 'Bad',
        regular: 'Regular',
        good: 'Good',
        excellent: 'Excellent',
        close: 'Close',
        evalLater: 'Evaluate later',
        eval: 'Evaluate'
      },
      mailSettings: {
        mailSettings: 'Manage email notification',
        whatNotification: 'What e-mail notifications would you like to receive?',
        disableNotification: 'Enable all notifications',
        schedule: 'Order confirmed',
        departure: 'Vehicle arrived in the courtyard',
        invoiced: 'Order invoiced',
        save: 'Save',
        manage: 'Manage notifications',
        sucess: 'Saved successfully.',
        error: 'An error has occured, try again later'
      },
      callCenter: {
        menu: 'Contact Us',
        newTicket: 'New Ticket',
        viewTicket: 'View Ticket',
        sector: 'Sector',
        number: 'Service Number',
        ticketLevelAll: 'All',
        pending: 'Pending Unipar',
        served: 'Served',
        finished: 'Finished',
        newPageTitle: 'Send request',
        viewHistoryPageTitle: 'Service history',
        viewDetailsPageTitle: 'Request Details',
        labelSubject: 'Subject',
        labelSubjectSelect: 'Select subject',
        labelPhone: 'Phone Number',
        labelPhonePlaceholder: 'Input phone number',
        labelDescription: 'Message',
        labelDescriptionPlaceholder: 'Input text',
        attachmentsCustomer: 'Attachments Customer',
        attachmentsUnipar: 'Attachments Unipar',
        userMessage: 'Message sent',
        uniparReply: 'Unipar Reply',
        servedWarningMessage: 'If there is no new interaction for this ticket in {{daysToFinishTicket}} day, this will be finished.',
        successMessage: 'Message sent successfully',
        errorMessage: 'Error sending message',
        finishedConfirmMessage: 'Confirm ticket close?',
        finishedMessage: 'Ticket closed',
        addReply: 'Add reply',
        finishTicket: 'Finish ticket',
        errorFinishMessage: 'Error finishing ticket'
      },
      usefulInformation: {
        usefulInformation: 'Useful information',
        bankingData: 'Banking data',
        registrantionInformation: 'Registration Information',
        bankDetail: 'Bank details',
        bank: 'Bank',
        agency: 'Agency',
        checkingAccount: 'Checking account',
        pixKey: 'Pix Key'
      },
      usefulInfo: 'Useful information',
      bulkEdit:{
        editOrders: 'Edit Orders',
        date: 'Date',
        portalUniparNr: 'Portal Unipar Nr',
        editSuccess: 'Orders altered successfully.',
        invalidQuantity: 'Invalid order quantity',
        checkRedFields: 'Check the fields in red',
        validationAlert: 'The highlighted items in red are as the weight diverges from the vehicle\'s capacity.',
        alertUnderWeight: 'Total order weight ( @totalOrderWeight t) below vehicle minimum ( @vehicleMinValue t).',
        alertOverWeight: 'Total order weight ( @totalOrderWeight t) exceeded vehicle limit ( @vehicleMaxValue t).',
      },
      messageErrorQuantity: 'Please review the order quantities with the truck indicated in red!',
      totalInVehicle: 'Total in vehicle',
      maxWeight: 'Maximum weight',
      minWeight: 'Minimum weight',
      multiple: 'Multiple',
      defaultErrorTitle: 'We were unable to process your request',
      defaultErrorBody: 'Please contact our customer service team.'
    }
  },
  pt: {
    translation: {
      welcome: 'Bem-vindo(a) ao Portal do Cliente Unipar',
      newOrder: 'Novo Pedido',
      myOrder: 'Meus Pedidos',
      financial: 'Financeiro/Documentos',
      logout: 'Sair',
      drakMode: 'Modo Escuro',
      home: 'Home',
      links: 'Links úteis',
      users: 'Usuários',
      interval: 'Período',
      intervalAll: 'Todos',
      createdAt: 'Data Criação',
      search: 'Buscar',
      noResults: 'Não foram encontrados resultados',
      attachFile: 'Anexar arquivo',
      addFile: 'Incluir anexo',
      permittedFiles: 'Arquivos permitidos: ',
      attachments: 'Anexos',
      fileRemoveModalTitle: 'Deseja remover o anexo?',
      close: 'Fechar',
      confirm: 'Confirmar',
      back: 'Voltar',
      send: 'Enviar',
      fieldErrorMandatoryMessage: 'Preenchimento é obrigatório',
      errorSearchMessage: 'Erro obtendo dados',
      errorRemoveMessage: 'Erro removendo dados',
      login: {
        title: 'Cliente Unipar',
        subtitle: 'Nosso propósito é ser confiável em todas as nossas relações',
        login: 'Entrar',
        forgotPass: 'Esqueci minha senha',
        register: 'Primeiro acesso',
        pass: 'Senha',
        passValidation: 'Por favor digite sua senha',
        emailValidation: 'Por favor digite seu email',
        robotBtn: 'Não sou um robô',
      },
      homeWidgets: {
        newOrderTitle: 'Faça um novo pedido',
        newOrderSubTitle: 'Solicite e acompanhe.',
        newOrderBtn: 'Fazer novo pedido',
        graphTitle: '- Status x Toneladas (mês)',
        graphTitleOrder: 'Pedidos',
        graphTotal: 'Total (toneladas)',
        graphBilled: 'Faturadas',
        notfoundOrders: 'Não encontramos pedidos para você no mês atual.',
        notfoundOrdersProgramming: 'Nehum pedido encontrado para o mês.',
        programTitle: 'Programação',
        myOrderWidgetTitle: 'Meus Pedidos',
        tonMonthTitle: 'Toneladas faturadas por mês',
        tdOrderNumber: 'Núm. Pedido',
        tdShipmentDate: 'DATA CARR.',
        tdDeliveryDate: "Prev. Entrega",
        tdShipmentQtd: 'QTDE.',
        tdStatus: 'STATUS',
        tdActions: 'AÇÕES',
        Jan: 'Jan',
        Fev: 'Fev',
        Mar: 'Mar',
        Abr: 'Abr',
        Mai: 'Mai',
        Jun: 'Jun',
        Jul: 'Jul',
        Ago: 'Ago',
        Set: 'Set',
        Out: 'Out',
        Nov: 'Nov',
        Dez: 'Dez',
        analise: 'Análise Unipar',
        pendenteCliente: 'Pendente Cliente',
        confirmado: 'Pedido Agendado',
        chegou: 'Chegou no pátio',
        entrou: 'Entrada na fábrica',
        carregando: 'Carregando',
        faturado: 'Faturado',
        cancelado: 'Cancelado',
        recusado: 'Recusado',
        analiseAlteracao: 'Análise de alteração',
        analiseCancelado: 'Análise de cancelamento',
        statusTonAnalise: 'Em análise Unipar',
        statusTonPendente: 'Pendente Cliente',
        statusTonChegou: 'Chegou no pátio',
        statusTonEntrou: 'Entrada na fábrica',
        statusTonFaturado: 'Faturados',
        showAllMyOrders: 'Visualizar todos',
        modalErrorRebuyTitle: 'Infelizmente não é possivel realizar a recompra para esse produto',
        modalErrorRebuyMsg: 'Mas você pode realizar um novo pedido',
        modalErrorRebuyBtn: 'Fazer novo pedido',
        modalErrorCancelTitle: 'Cancelamento de Pedido',
        modalErrorCancelMsg: 'Tem certeza que deseja cancelar o pedido?',
        modalErrorCancelBtnY: 'Sim',
        modalErrorCancelBtnN: 'Não',
        modalErrorCancelBtnClose: 'Fechar',
        modalErrorCancelFeedbackOk: 'Pedido cancelado com sucesso!',
        modalErrorCancelFeedbackErr: 'Não foi possível cancelar o pedido. ',
      },
      myOrders: {
        newOrder: 'Novo Pedido',
        myOrders: 'Meus Pedidos',
        searchFor: 'Buscar por',
        search: 'Buscar',
        interval: 'Período',
        intervalAll: 'Todos',
        intervalCurrentMonth: 'Mês Atual',
        intervalNextMonth: 'Próximo Mês',
        intervalLastMonth: 'Último mês',
        intervalLastSixty: 'Últimos 60 dias',
        intervalLastNinety: 'Últimos 90 dias',
        product: 'Produto',
        uniparAnalysis: 'Análise Unipar',
        customerPendency: 'Pendente Cliente',
        orderConfirmed: 'Pedido Agendado',
        arrived: 'Chegou no pátio',
        entered: 'Entrou no pátio',
        loaded: 'Carregado',
        invoiced: 'Faturado',
        refused: 'Recusado',
        changeAnalysis: 'Análise de alteração',
        cancellationAnalysis: 'Análise de cancelamento',
        cancelOrder: 'Cancelar pedidos',
        exportOrders: 'Exportar pedidos',
        noResults: 'Não foram encontrados resultados',
        uniparOrderNumber: 'Número do pedido Unipar',
        materialNumber: 'Código do material',
        paymentConditions: 'Condição de pagamento',
        orderItem: 'Item do pedido',
        deposit: 'Depósito',
        totalValue: 'Valor total',
        portalOrderNumber: 'Número pedido portal',
        typeOfShipping: 'Tipo de Frete',
        actions: 'Ações',
        rebuy: 'Recomprar',
        edit: 'Editar',
        cancel: 'Cancelar',
        approveRefuse: 'Aprovar/Recusar',
        changeReview: 'Revisar Alterações',
        checkDetails: 'Ver Detalhes',
        orderDetails: 'Detalhes do pedido',
        unfortunately: 'Infelizmente não é possivel realizar a recompra para esse produto',
        doNewOrder: 'Mas você pode realizar um novo pedido',
        cancelSelectedOrder: 'Tem certeza que deseja cancelar o(s) pedido(s) selecionado(s)?',
        yes: 'Sim',
        no: 'Não',
        close: 'Fechar',
        unableDownload: 'Não foi possivel realizar o dowload do excel, por favor tente novamente!',
        customerOrderNumber: 'Nr Pedido Cliente',
        uniparOrderNumberAbr: 'Nr Pedido Unipar',
        transporter: 'Transportadora',
        shipmentDate: 'Data Carr.',
        deliveryDate: 'Data Prev. Entrega',
        shipmentQnt: 'Quantidade',
        materialDescription: 'Descrição do Material',
        reasonCancelation: 'Cancelado pelo cliente',
        orderCancelSuccess: 'Pedido(s) cancelado(s) com sucesso!',
        orderErrorCancelSuccess: 'Não foi possível cancelar os pedido(s) selecionado(s). ',
        select: 'Selecione',
        aproveChanges: 'Aprovar alterações',
        labelDateIn: 'Data de',
        labelDateUntil: 'Data até',
        labelInvalidDate: 'Data inválida',
        labelMsgErrorDateOut: 'Por favor selecione uma data final maior que a inicial!',
        labelMsgErrorDateInitial: 'Por favor selecione uma data inicial!',
        observations: 'Observações',
        editOrders: 'Editar Pedidos',
        alertSelectOneOrder: 'Selecione ao menos um pedido!'
      },
      myOrdersDetails: {
        orderNumber: 'PEDIDO Nº',
        orderInternalNumber: 'PEDIDO INTERNO Nº',
        deliveryAt: 'ENTREGA/CARR. EM',
        center: 'CENTRO',
        transporter: 'TRANSPORTADORA',
        quantity: 'QUANTIDADE',
        vehicle: 'VEÍCULO',
        receiver: 'RECEBEDOR',
        date: 'CRIADA EM',
        shipping: 'REMESSA',
        plate: 'PLACA',
      },
      pagination: {
        itensPerPage: 'Itens por página'
      },
      forgotPass: {
        msgEmail: 'Digite seu email para resetar sua senha',
        verifyEmail: 'Verificar email',
        back: 'Voltar',
        validationEmail: 'Por favor digite seu email',
        msgCode: 'Enviamos um código para o email informado, por favor o insira no campo abaixo',
        inputCode: 'Código',
        verifyCode: 'Verificar código',
        backLogin: 'Voltar para o login',
        validationCode: 'Por favor digite o código',
        msgNewPass: 'Defina e confirme sua nova senha',
        inputNewPass: 'Nova senha',
        inputConfirmNewPass: 'Confirmar nova senha',
        validationNewPass: 'Por favor digite sua senha',
        validationConfirmPass: 'Por favor confirme sua nova senha',
        verifyNewPass: 'Alterar senha',
        validationMsg: 'A sua senha deve conter os seguintes requisitos:',
        validationLength: '- Ter ao menos 8 dígitos',
        validationNumber: '- Ter ao menos 1 número',
        validationLower: '- Ter uma letra maiúscula',
        validationUpper: '- Ter uma letra minúscula',
        validationSpecial: '- Ter ao menos 1 carácter especial',
        validationMatch: '- Senha e confirmação de senha iguais',
        notReceiveCode: 'Não recebeu o código',
        msgResend: 'Você poderá solicitar o reenvio do código em ',
        seconds: 'segundos',
        erroEmail: 'Email digitado errado',
        errorCode: 'Código inválido ou expirado',
        errorPass: 'Não foi possivel alterar sua senha',
        resendEmail: 'Reenviar email',
        tooltipResend: 'Verifique em sua caixa de spam ou lixo eletrônico.',
        erroEmailContato: 'Por favor entrar em contato com o Atendimento ao Cliente:',
        erroEmailContatoPVCBrasil: 'PVC Brasil - atendimentopvc@unipar.com',
        erroEmailContatoQuimicoBrasil: 'Químicos Brasil - servicoaocliente@unipar.com',
        erroEmailContatoArgentina: 'PVC e Químicos Argentina - atencionalcliente@unipar.com'
      },
      firstAccess: {
        msgEmail: 'Digite seu email para criar o seu acesso',
        modalTitle: 'Tudo certo!',
        modalBody: 'Seu acesso foi criado, agora é só realizar seu login.',
        modalBtn: 'Ir para login'
      },
      utilLinksScreen:
      {
        labelLinkDescCorporateSite: 'Site Corporativo',
        labelLinkDescChemicalProducts: 'Produtos Químicos',
        labelLinkDescPVC: 'Produtos PVC',
        labelLinkDescSGI: 'Certificações SGI',
        labelCountry: 'Brasil',
        labelLinkUtil: 'Links Úteis'
      },
      financialScreen:
      {
        labelSelect: 'Selecione',
        labelInvoice: 'NFe',
        labelEmissionDate: 'Emissão',
        labelExpiredDate: 'Vencimento',
        labelSalesNumber: 'Doc. de venda',
        labelTotalValue: 'Valor total',
        labelStatus: 'Status',
        labelAction: 'Documentos',
        labelDocumentsFinancial: 'Financeiro/Documentos',
        labelNfNumber: 'Número da NF',
        labelSearch: 'Busca',
        labelSearchPlaceholder: 'Buscar por NF ou Pedido Cliente',
        labelPeriod: 'Tipo de Período',
        labelForEmission: 'Por Emissão',
        labelForExpired: 'Por Vencimento',
        labelDateIn: 'Data de',
        labelDateUntil: 'Data até',
        labelMsgErrorDateOut: 'Por favor selecione uma data final maior que a inicial!',
        labelMsgErrorDateInitial: 'Por favor selecione uma data inicial!',
        labelOverdue: 'Vencido',
        labelPaidOut: 'Compensado',
        labelPending: 'À Vencer',
        labelAll: 'Todos',
        labelExportExcel: 'Exportar dado(s) para excel',
        labelExportExcelBtn: 'Exportar Excel',
        labelExportXml: 'Baixar XML',
        labelExportDanfe: 'Baixar DANFE',
        labelExportInvoice: 'Baixar Fatura',
        labelExportRemito: 'Baixar Remito',
        labelExportCertificate: 'Baixar Certificado',
        labelNotFound: 'Não foram encontrados resultados',
        labelGenerateBankBill: 'Gerar Boleto',
        labelDownloadXML: 'Baixar XML',
        labelXmlError: 'XML ainda não disponível, por favor tente novamente mais tarde!',
        labelDownloadDANFE: 'Baixar DANFE',
        labelDANFEError: 'DANFE ainda não disponível, por favor tente novamente mais tarde!',
        labelDownloadCertify: 'Baixar Certificado',
        labelCertifyError: 'Certificado ainda não disponível, por favor tente novamente mais tarde!',
        labelRemitoError: 'Remito ainda não disponível, por favor tente novamente mais tarde!',
        labelInvalidDate: 'Data inválida',
        labelErrorDownloadComplete: 'Não foi possível completar o download',
        labelDownloadPending: 'Realizando download...',
        labelCustomerOrder: 'Pedido Cliente',
        labelQuantity: 'Qtde. (Ton)',
      },
      newOrderScreen: {
        rulesTitle: 'Regras para inclusões, alterações e cancelamentos de pedidos:',
        rule1: 'Inclusões e alterações para o mesmo dia, favor entrar em contato com o Atendimento ao Cliente.',
        rule2: 'Inclusões e alterações para o dia seguinte, devem ser enviadas até as 11:00 do dia anterior ao carregamento, de segunda à sexta.',
        rule3: 'Solicitações enviadas fora do horário administrativo (após as 17:00), finais de semana e feriados, serão analisadas no próximo dia útil.',
        rule4: 'Em caso de urgência, contatar o Atendimento ao Cliente.',
        newOrderTitle: 'Novo pedido',
        labelProductInformation: 'Informações do produto',
        labelProduct: 'Produto',
        labelTotalVolume: 'VOLUME TOTAL',
        labelOrder: 'PEDIDOS',
        labelSelect: 'Selecione',
        labelOrderDetail: 'Dados do pedido',
        labelDeliveryPlace: 'Local de entrega *',
        labelFreight: 'Frete *',
        labelPaymentConditions: 'Condições de pagamento *',
        labelBulkOrder: 'Gerar pedidos em massa',
        labelBulkOrderEditCIF: 'Importar entregas em massa',
        labelBulkOrderEditFOB: 'Importar carregamento em massa',
        labelChangesInformationCIF: '*Qualquer informação alterada acima pode causar alterações nos carregamentos já adicionados.',
        labelChangesInformationFOB: '*Qualquer informação alterada acima pode causar alterações nas entregas já adicionadas.',
        labelRequiredFields: '* Campos obrigatórios',
        labelRequiredFieldsValidate: 'Confira os campos obrigatórios',
        labelSendOrder: 'Enviar Pedido',
        labelAdd: 'Adicionar',
        labelLoad: 'carregamento',
        labelDelivery: 'entrega',
        labelLoadResume: 'Carrega em',
        labelDeliveryResume: 'Entrega em',
        labelImportFileOrderIntent: 'Importar pedidos em massa',
        labelCancel: 'Cancelar',
        labelImport: 'Importar',
        labelChooseFile: 'Selecionar Arquivo',
        labelFileSelected: 'Arquivo selecionado',
        labelTextModelPart1: 'Você pode importar várias datas de carregamento utilizando uma planilha. Baixe o modelo',
        labelTextModelPart2: 'ou',
        labelTextModelPart3: 'para fazer o envio corretamente',
        labelCIFFile: 'Unipar entrega (CIF)',
        labelFOBFile: 'Cliente retira (FOB)',
        labelSFRFile: 'Sem Frete (SFR)',
        labelIncotermDateFOB: 'Data de Carregamento',
        labelIncotermDateCIF: 'Data Pretendida de Entrega',
        labelIncotermDateEditCIF: 'Data Pretendida de Entrega',
        labelCPTFile: 'Unipar entrega (CPT)',
        labelFCAFile: 'Cliente retira (FCA)',
        labelTextModelARPart1: 'Você pode importar várias datas de carregamento utilizando uma planilha. Baixe o ',
        labelTextModelARPart2: 'modelo',
        labelTextModelARPart3: 'aqui para fazer o envio corretamente',
        labelVehicleType: 'Tipo de veículo',
        labelDeposit: 'Depósito',
        labelVehiclesQty: 'Qtde. de veículos',
        labelTotalQty: 'Qtde. Total',
        labelDeliveryText: 'Entrega',
        labelQuantityWeight: 'Quantidade (ton) *',
        labelVehicle: 'Veículo',
        labelDelete: 'Excluir',
        labelShippingCompany: 'Transportadora',
        labelOrderNumber: 'Nº do seu pedido',
        labelMaterialNumber: 'Nº do material',
        labelOrdemItem: 'Item do pedido',
        labelModalHeaderTextValidDate: 'Data selecionada inválida!',
        labelModalHeaderTextValidQuantity: 'Quantidade selecionada inválida!',
        labelInformationDANFE: 'Campo não obrigatório, inserir somente se necessário esta informação no DANFE',
        returnValidEqualDate: 'Não é possível agendar entregas para o mesmo dia da solicitação!',
        returnValidPassedDate: 'Não é possível agendar entregas para dias passados!',
        labelModalHeaderFileImport: 'Arquivo de Importação',
        labelModalFileImportButton: 'Entendi',
        returnValidEndofWeek: 'Não é possível agendar carregamentos para materiais PVC em finais de semana!',
        returnValidAfter17Hour: 'Solicitações enviadas após as 17 horas ou em finais de semana para entregas no dia útil seguinte, serão análisadas no próximo dia útil!',
        returnValidAfter11Hour: 'Não é possivel agendar carregamentos para o dia útil seguinte após às 11 horas, neste caso, favor entrar em contato com a Unipar.',
        returnValidAfter11FridayHour: 'Não é possível agendar entregas para o fim de semana após as 11 horas de sexta!',
        returnErrorSelectedProduct: 'Selecione um produto!',
        returnErrorSelectedMethodDelivery: 'Selecione uma forma de entrega!',
        returnOKImportFile: 'Arquivo importado com sucesso!',
        returnErrorExtensionFiles: 'Apenas é possivel importar arquivos com a extenção .csv, .xls e .xlsx',
        labelSelectedReceiver: 'Por favor selecione um recebedor',
        labelSelectedIncoterm: 'Por favor selecione um tipo de frete',
        labelPaymentCondition: 'Por favor selecione uma condição de pagamento',
        labelMessageDeliveryDate: 'Por favor adicione uma data para sua entrega',
        labelReturnOkOrder: 'Solicitação enviada com sucesso!',
        labelComingSoon: 'Em breve retornaremos',
        labelAccessArea: 'Para acompanhá-lo acesso a área',
        labelMyOrders: 'meus pedidos',
        labelForMyOrders: 'Ir para meus pedidos',
        labelErrorMessageOrder: 'Ocorreu um erro ao solicitar o pedido',
        labelImportFile: 'Importação de pedidos',
        labelErrorImportFile: 'Ocorreram os seguintes erros na importação do arquivo:',
        labelMultipleQuantityError: 'A quantidade informada não é múltipla do produto',
        labelMultipleQuantityErrorDetail: 'A quantidade [informedQuantity] não é múltipla de [multipleWeight]',
        labelQtyInTruck: 'Qtde. no caminhão',
        labelOrderNotFound: 'Não foi possivel consultar o pedido selecionado, por favor tente novamente.',
        labelBack: 'Voltar',
        labelErrorFindOrder: 'Ocorreu um erro ao consultar o pedido',
        labelFileModel: 'Arquivo de Modelo de Importação',
        labelOrderItem: 'Itens dos pedidos',
        labelQtyResume: 'Quantidade',
        labelAddObservation: 'Adicionar observações',
        labelEditOrder: 'Editar Pedido',
        labelFinalizeOrder: 'Finalizar Pedido',
        labelErrorDateOutOfLimit: 'Não é possivel editar o pedido, pois já se passaram das 11 horas e o pedido é para o próximo dia útil.',
        labelErrorDatePassed: 'Não é possivel editar o pedido, pois a data do mesmo é uma data passada.',
        labelErrorDateEqual: 'Não é possivel editar o pedido, pois a data do mesmo é para o mesmo dia de hoje.',
        labelSaveChanges: 'Salvar alterações',
        labelConfirmAlterOrder: 'Solicitação de alterações enviadas com sucesso!',
        labelErrorAlterOrder: 'Ocorreu um erro ao alterar o pedido',
        labelObservation: 'Observação',
        titleErrorOnSap: 'Não conseguimos processar alguns pedidos.',
        errorOnSapLineOne: 'Infelizmente não foi possível processar alguns dos seus pedidos. Deseja tentar comprar os pedidos não processados novamente ?',
        errorOnSapLineTwo: 'Caso o erro persista, favor entrar em contato com a equipe de atendimento.',
        materialsWithError: 'Não foi possível alterar os materiais:',
        editErrorOnSapLineOne: 'Infelizmente não foi possível processar alguns dos seus pedidos. Deseja tentar atualizar os pedidos não processados novamente ?'
      },
      notifications: 'Notificações',
      footer: {
        labelPrivacyPolicies: "Políticas de Privacidade"
      },
      aproveChangesScreen: {
        tdShipmentDate: 'DATA CARR.',
        tdShipmentQuantity: 'Quantidade',
        tdVeichleTypeName: 'Nome do veículo',
        tdTransporterName: 'Nome da Transportadora',
        tdOrderNumber: 'Número Pedido',
        tdMaterialDescription: 'Item do pedido',
        tdSelect: 'Selecione',
        labelAproveOrder: 'Aprovar alteração de pedidos',
        labelOrderNumber: 'Número do seu pedido',
        labelMaterialCode: 'Número do Material',
        labelPaymentConditions: 'Condições de pagamento',
        labelMaterialDescription: 'Descrição do material',
        labelPendenciesAprove: 'Aprovações Pendentes',
        labelAllAprove: 'Aprovar Todos',
        labelNotFound: 'Não foram encontrados resultados',
        labelDelivery: 'Entrega',
        labelQuantity: 'Quantidade',
        labelVehicle: 'Veículo',
        labelTransporter: 'Transportador',
        labelOrderItem: 'Item do pedido',
        labelModify: 'Modificado em',
        labelObs: 'Observação',
        labelBack: 'Voltar',
        labelSave: 'Salvar',
        labelMsgSave: 'Alteração salva com sucesso!',
        labelComingSoon: 'Em breve retornaremos',
        labelAccessArea: 'Para acompanhar acesse a área',
        labelMyOrders: 'Meus Pedidos',
        labelGotoMyOrders: 'Ir para meus pedidos',
        labelErrorSave: 'Ocorreu um erro ao salvar as alterações',
        labelOK: 'Entendi',
        labelTryAgain: 'Por favor tente novamente!'
      },
      customerSurvey: {
        step1Title: 'Sua opinião é muito importante para as melhorias em nosso Portal, afinal ele foi feito para você!',
        step2Title: 'Sua opinião é muito importante para as melhorias em nosso Portal, afinal ele foi feito para você!',
        step3Title: 'Sua opinião é muito importante para as melhorias em nosso Portal, afinal ele foi feito para você!',
        step4Title: 'Obrigado pela sua avaliação!',
        step1Description: 'Em uma escala de 1 a 5, como você avalia sua experiência utilizando nossa plataforma?',
        step2Description: 'Em uma escala de 1 a 5, o quanto você recomendaria nosso Portal para um amigo ou colega?',
        step3Description: 'Você gostaria de deixar algum comentário ou sugestão para nos ajudar a melhorar nossa plataforma?',
        step4Description: 'Sua avaliação nos ajuda a entender como podemos melhorar nossa plataforma. Seu feedback é extremamente valioso para nós!',
        step3Comments: 'Escreva aqui seu comentário',
        terrible: 'Péssimo',
        bad: 'Ruim',
        regular: 'Regular',
        good: 'Bom',
        excellent: 'Ótimo',
        close: 'Fechar',
        evalLater: 'Avaliar depois',
        eval: 'Avaliar'
      },
      mailSettings: {
        mailSettings: 'Gerenciar notificação por e-mail',
        whatNotification: 'Quais notificações por e-mail gostaria de receber?',
        disableNotification: 'Ativar todas as notificações',
        schedule: 'Pedido agendado',
        departure: 'Veículo chegou no Pátio',
        invoiced: 'Pedido faturado',
        save: 'Salvar',
        manage: 'Gerenciar notificações',
        sucess: 'Salvo com sucesso.',
        error: 'Ocorreu um erro, tente novamente mais tarde'
      },
      callCenter: {
        menu: 'Fale Conosco',
        newTicket: 'Novo Atendimento',
        viewTicket: 'Minha Solicitação',
        sector: 'Área',
        number: 'Nº Solicitação',
        ticketLevelAll: 'Todos',
        pending: 'Pendente Unipar',
        served: 'Atendido',
        finished: 'Finalizado',
        newPageTitle: 'Envia sua solicitação',
        viewHistoryPageTitle: 'Histórico de atendimento',
        viewDetailsPageTitle: 'Detalhes da solicitação',
        labelSubject: 'Assunto',
        labelSubjectSelect: 'Selecione o assunto',
        labelPhone: 'Telefone',
        labelPhonePlaceholder: 'Insira o número de contato',
        labelDescription: 'Mensagem',
        labelDescriptionPlaceholder: 'Insira o texto',
        attachmentsCustomer: 'Anexos Cliente',
        attachmentsUnipar: 'Anexos Unipar',
        userMessage: 'Mensagem enviada',
        uniparReply: 'Resposta Unipar',
        servedWarningMessage: 'Se não houver uma nova interação, seja uma adição de resposta ou a finalização da solicitação, esse atendimento será finalizado automaticamente em {{daysToFinishTicket}} dias.',
        successMessage: 'Mensagem enviada com sucesso',
        errorMessage: 'Erro ao enviar mensagem',
        finishedConfirmMessage: 'Confirma a finalização do atendimento?',
        finishedMessage: 'Atendimento finalizado',
        addReply: 'Adicionar resposta',
        finishTicket: 'Finalizar atendimento',
        errorFinishMessage: 'Erro finalizando atendimento'
      },
      usefulInformation: {
        usefulInformation: 'Informações úteis',
        bankingData: 'Dados bancários',
        registrantionInformation: 'Informações de cadastro',
        bankDetail: 'Dados do banco',
        bank: 'Banco',
        agency: 'Agência',
        checkingAccount: 'Conta corrente',
        pixKey: 'Chave Pix'
      },
      usefulInfo: 'Informações Úteis',
      bulkEdit:{
        editOrders: 'Editar Pedidos',
        portalUniparNr: 'Nr Portal Unipar',
        date: 'Data',
        editSuccess: 'Pedidos alterados com sucesso.',
        invalidQuantity: 'Quantidade do pedido inválida',
        checkRedFields: 'Verifique os campos em vermelho',
        validationAlert: 'Os itens destacados em vermelho estão com o peso divergente da capacidade do veículo.',
        alertUnderWeight: 'Peso total do pedido ( @totalOrderWeight t) abaixo mínimo do veículo ( @vehicleMinValue t).',
        alertOverWeight: 'Peso total do pedido ( @totalOrderWeight t) excedeu limite do veículo ( @vehicleMaxValue t).',
      },
      messageErrorQuantity: 'Por favor revise as quantidades dos pedidos com o caminhão indicado em vermelho!',
      totalInVehicle: 'Total no veículo',
      maxWeight: 'Peso máximo',
      minWeight: 'Peso minímo',
      multiple: 'Múltiplo',
      defaultErrorTitle: 'Não foi possível processar a sua solicitação',
      defaultErrorBody: 'Favor entrar em contato com a equipe de atendimento.'
    }
  },
  es: {
    translation: {
      welcome: 'Bienvenido al Portal de Clientes de Unipar',
      newOrder: 'Nuevo Pedido',
      myOrder: 'Mis pedidos',
      financial: 'Finanzas',
      logout: 'Sair',
      drakMode: 'Modo Oscuro',
      home: 'Inicio',
      links: 'Links',
      users: 'Usuarios',
      interval: 'Período',
      intervalAll: 'Todos',
      createdAt: 'Fecha de Creación',
      search: 'Buscar',
      noResults: 'No se encontraron resultados',
      attachFile: 'Adjuntar archivo',
      permittedFiles: 'Archivos permitidos: ',
      attachments: 'Archivos adjuntos',
      addFile: 'Incluir archivo adjunto',
      fileRemoveModalTitle: '¿Quieres eliminar el archivo adjunto?',
      close: 'Cerrar',
      confirm: 'Confirmar',
      back: 'Volver',
      send: 'Mandar',
      fieldErrorMandatoryMessage: 'Valor es obligatorio',
      errorSearchMessage: 'Error al obtener datos',
      errorRemoveMessage: 'Error al eliminar datos',
      login: {
        title: 'Cliente Unipar',
        subtitle: 'Nuestro propósito es ser confiables en todas nuestras relaciones',
        login: 'Entrar',
        forgotPass: 'Olvide mi contraseña',
        register: 'Primer acceso',
        pass: 'Contraseña',
        passValidation: 'Por favor, escriba su contraseña',
        emailValidation: 'Por favor, escriba su email',
        robotBtn: 'No soy un robot',
      },
      homeWidgets: {
        newOrderTitle: 'Hacer un nuevo pedido',
        newOrderSubTitle: 'Solicitar y acompañar.',
        newOrderBtn: 'Hacer nuevo pedido',
        graphTitle: '- Status x Toneladas (mes)',
        graphTitleOrder: 'Pedidos',
        graphTotal: 'Total (toneladas)',
        graphBilled: 'Facturado',
        notfoundOrders: 'No pudimos encontrar ningún pedido para usted en el mes actual.',
        notfoundOrdersProgramming: 'No se encontraron pedidos para el mes.',
        programTitle: 'Programación',
        myOrderWidgetTitle: 'Mis pedidos',
        tonMonthTitle: 'Toneladas facturadas por mes',
        tdOrderNumber: 'Num. Pedido',
        tdShipmentDate: 'DATA CARR.',
        tdDeliveryDate: "Prev. Entrega",
        tdShipmentQtd: 'QTD.',
        tdStatus: 'ESTADO',
        tdActions: 'ACCIONES',
        Jan: 'Ene',
        Fev: 'Feb',
        Mar: 'Mar',
        Abr: 'Abr',
        Mai: 'May',
        Jun: 'Jun',
        Jul: 'Jul',
        Ago: 'Ago',
        Set: 'Sep',
        Out: 'Oct',
        Nov: 'Nov',
        Dez: 'Dic',
        analise: 'Análisis Unipar',
        pendenteCliente: 'Cliente Pendiente',
        confirmado: 'Pedido Agendado',
        chegou: 'Llegó a la corte',
        entrou: 'Entrada en la fábrica',
        carregando: 'Cargado',
        faturado: 'Facturado',
        cancelado: 'Cancelado',
        recusado: 'Rechazado',
        analiseAlteracao: 'Análisis de cambios',
        analiseCancelado: 'Análisis de cancelación',
        statusTonAnalise: 'En análisis Unipar',
        statusTonPendente: 'Cliente Pendiente',
        statusTonChegou: 'Llegó a la corte',
        statusTonEntrou: 'Entrada en la fábrica',
        statusTonFaturado: 'Facturado',
        showAllMyOrders: 'Mostrar todo',
        modalErrorRebuyTitle: 'Desafortunadamente, no es posible recomprar este producto',
        modalErrorRebuyMsg: 'Pero puedes realizar un nuevo pedido',
        modalErrorRebuyBtn: 'Nuevo pedido',
        modalErrorCancelTitle: 'Cancelación de pedido',
        modalErrorCancelMsg: '¿Está seguro de que desea cancelar el pedido?',
        modalErrorCancelBtnY: 'Sí',
        modalErrorCancelBtnN: 'No',
        modalErrorCancelBtnClose: 'Cerrar',
        modalErrorCancelFeedbackOk: '¡Pedido cancelado exitosamente!',
        modalErrorCancelFeedbackErr: 'No se puede cancelar el pedido.',
      },
      myOrders: {
        newOrder: 'Nuevo Pedido',
        myOrders: 'Mis Pedidos',
        searchFor: 'Búsqueda por',
        search: 'Buscar',
        interval: 'Período',
        intervalAll: 'Todos',
        intervalCurrentMonth: 'Mes Actual',
        intervalNextMonth: 'proximo mes',
        intervalLastMonth: 'Último mes',
        intervalLastSixty: 'Últimos 60 días',
        intervalLastNinety: 'Últimos 90 días',
        product: 'Producto',
        uniparAnalysis: 'Análisis Unipar',
        customerPendency: 'Pendiente Cliente',
        orderConfirmed: 'Orden Confirmada',
        arrived: 'Llegó al patio',
        entered: 'Entró al patio',
        loaded: 'Cargado',
        invoiced: 'Facturado',
        refused: 'Rechazado',
        changeAnalysis: 'Análisis de cambios',
        cancellationAnalysis: 'Análisis de cancelación',
        cancelOrder: 'Cancelar pedidos',
        exportOrders: 'Órdenes de exportación',
        noResults: 'No se encontraron resultados',
        uniparOrderNumber: 'Número de pedido Unipar',
        materialNumber: 'Código material',
        paymentConditions: 'Condición de pago',
        orderItem: 'Articulo ordenado',
        deposit: 'Depósito',
        totalValue: 'Valor total',
        portalOrderNumber: 'Número pedido del portal',
        typeOfShipping: 'Tipo de Envío',
        actions: 'Acciones',
        rebuy: 'Recomprar',
        edit: 'Editar',
        cancel: 'Cancelar',
        approveRefuse: 'Aprobar/Rechazar',
        changeReview: 'Revisar cambios',
        checkDetails: 'Ver detalles',
        orderDetails: 'Detalles del pedido',
        unfortunately: 'Lamentablemente, no es posible recomprar este producto',
        doNewOrder: 'pero puedes realizar un nuevo pedido',
        cancelSelectedOrder: '¿Está seguro de que desea cancelar los pedidos seleccionados?',
        yes: 'Si',
        no: 'No',
        close: 'Cerrar',
        unableDownload: 'No se puede descargar Excel, ¡inténtelo de nuevo!',
        customerOrderNumber: 'Nro. Pedido Cliente',
        uniparOrderNumberAbr: 'Nro. Pedido Unipar',
        transporter: 'Transportadora',
        shipmentDate: 'Data Carr.',
        deliveryDate: 'Entrega',
        shipmentQnt: 'Cantidad',
        materialDescription: 'Descripción del material',
        reasonCancelation: 'Cancelado por el cliente',
        orderCancelSuccess: '¡Pedido(s) cancelado(s) exitosamente!',
        orderErrorCancelSuccess: 'No se pueden cancelar los pedido(s) seleccionado(s).',
        select: 'Seleccionar',
        aproveChanges: 'Aprobar cambios',
        labelDateIn: 'Fecha de',
        labelDateUntil: 'Fecha hasta',
        labelInvalidDate: 'Fecha invalida',
        labelMsgErrorDateOut: '¡Seleccione una fecha de finalización posterior a la fecha de inicio!',
        labelMsgErrorDateInitial: '¡Seleccione una fecha de inicio!',
        observations: 'Observaciones',
        editOrders: 'Editar Pedidos',
        alertSelectOneOrder: '¡Seleccione al menos un pedido!'
      },
      myOrdersDetails: {
        orderNumber: 'PEDIDO Nº',
        orderInternalNumber: 'PEDIDO INTERNO Nº',
        deliveryAt: 'ENTREGA/COCHE. EN',
        center: 'CENTRO',
        transporter: 'TRANSPORTADORA',
        quantity: 'CANTIDAD',
        vehicle: 'VEHÍCULO',
        receiver: 'RECEPTOR',
        date: 'CREADO EN',
        shipping: 'REMESSA',
        plate: 'PLACA',
      },
      pagination: {
        itensPerPage: 'Artículos por página'
      },
      forgotPass: {
        msgEmail: 'Ingrese su correo electrónico para restablecer su contraseña',
        verifyEmail: 'Revisar correo electrónico',
        back: 'Volver',
        validationEmail: 'Por favor, escriba su correo electrónico',
        msgCode: 'Hemos enviado un código a correo electrónico proporcionada, ingréselo en el campo a continuación',
        inputCode: 'Código',
        verifyCode: 'Verificar código',
        backLogin: 'Volver para login',
        validationCode: 'Por favor ingrese el código',
        msgNewPass: 'Establece y confirma tu nueva contraseña',
        inputNewPass: 'Nueva contraseña',
        inputConfirmNewPass: 'Confirmar nueva contraseña',
        validationNewPass: 'Por favor, introduzca su contraseña',
        validationConfirmPass: 'Por favor confirme su nueva contraseña',
        verifyNewPass: 'Cambiar contraseña',
        validationMsg: 'Su contraseña debe contener los siguientes requisitos:',
        validationLength: '- Tener al menos 8 dígitos',
        validationNumber: '- Tener al menos 1 número',
        validationLower: '- Tener una letra mayúscula',
        validationUpper: '- Tener una letra minúscula',
        validationSpecial: '- Tener al menos 1 carácter especial',
        validationMatch: '- Contraseña y confirmación de contraseña igual',
        notReceiveCode: '¿No recibiste el código',
        msgResend: 'Puedes solicitar el reenvío del código en ',
        seconds: 'segundos',
        erroEmail: 'Correo electrónico mal escrito',
        errorCode: 'Código no válido o caducado',
        errorPass: 'No se puede cambiar su contraseña',
        resendEmail: 'Reenviar email',
        tooltipResend: 'Revisa tu carpeta de spam o basura.',
        erroEmailContato: 'Por favor contacte con el Servicio de Atención al Cliente:',
        erroEmailContatoPVCBrasil: 'PVC Brasil - atendimentopvc@unipar.com',
        erroEmailContatoQuimicoBrasil: 'Quimicos Brasil - servicoaocliente@unipar.com',
        erroEmailContatoArgentina: 'PVC e Quimicos Argentina - atencionalcliente@unipar.com'
      },
      firstAccess: {
        msgEmail: 'Introduce tu email para crear tu acceso',
        modalTitle: '¡Muy bien!',
        modalBody: 'Su acceso ha sido creado, ahora solo inicie sesión.',
        modalBtn: 'Ir a iniciar sesión'
      },
      utilLinksScreen:
      {
        labelLinkDescCorporateSite: 'Web Corporativa',
        labelLinkDescChemicalProducts: 'Productos Químicos',
        labelLinkDescPVC: 'Productos PVC',
        labelLinkDescSGI: 'Certificaciones SGI',
        labelCountry: 'Brasil',
        labelLinkUtil: 'Links Útiles'
      },
      financialScreen:
      {
        labelSelect: 'Seleccionar',
        labelInvoice: 'NFe',
        labelEmissionDate: 'Emisión',
        labelExpiredDate: 'Vencimiento',
        labelSalesNumber: 'Doc. de venta',
        labelTotalValue: 'Valor total',
        labelStatus: 'Status',
        labelAction: 'Documentos',
        labelDocumentsFinancial: 'Financiero/Documentos',
        labelNfNumber: 'Factura',
        labelSearch: 'Buscar',
        labelSearchPlaceholder: 'Buscar por NF ou Pedido Cliente',
        labelPeriod: 'Tipo de Período',
        labelForEmission: 'Por Emisión',
        labelForExpired: 'Por Vencimiento',
        labelDateIn: 'Fecha de',
        labelDateUntil: 'Fecha hasta',
        labelMsgErrorDateOut: '¡Seleccione una fecha de finalización posterior a la fecha de inicio!',
        labelMsgErrorDateInitial: '¡Seleccione una fecha de inicio!',
        labelOverdue: 'Atrasado',
        labelPaidOut: 'Pagado',
        labelPending: 'Pendiente',
        labelAll: 'Todo',
        labelExportExcel: 'Exportar dato(s) a excel',
        labelExportExcelBtn: 'Exportar Excel',
        labelExportXml: 'Descargar XML',
        labelExportDanfe: 'Descargar DANFE',
        labelExportInvoice: 'Descargar Factura',
        labelExportRemito: 'Descargar Remito',
        labelExportCertificate: 'Descargar Certificado',
        labelNotFound: 'No se encontraron resultados',
        labelGenerateBankBill: 'Generar factura',
        labelDownloadXML: 'Descargar XML',
        labelXmlError: 'XML aún no está disponible. Inténtelo de nuevo más tarde.',
        labelDownloadDANFE: 'Descargar DANFE',
        labelDANFEError: 'DANFE aún no está disponible. Inténtelo de nuevo más tarde.',
        labelDownloadCertify: 'Descargar Certificado',
        labelCertifyError: 'Certificado aún no está disponible. Inténtelo de nuevo más tarde.',
        labelRemitoError: 'Remito aún no está disponible, ¡inténtalo de nuevo más tarde!',
        labelInvalidDate: 'Fecha invalida',
        labelErrorDownloadComplete: 'No se puede completar la descarga',
        labelDownloadPending: 'Descargando...',
        labelCustomerOrder: 'Solicitud cliente',
        labelQuantity: 'Cantidad (Ton)',
      },
      newOrderScreen: {
        rulesTitle: 'Reglas para inclusiones, cambios y cancelaciones de pedidos:',
        rule1: 'Adiciones y cambios en el mismo día, comuníquese con Servicio al Cliente.',
        rule2: 'Las inclusiones y cambios para el día siguiente deben enviarse antes de las 11:00 am del día anterior a la carga, de lunes a viernes.',
        rule3: 'Las solicitudes enviadas fuera del horario administrativo (después de las 5:00 pm), fines de semana y feriados, serán revisadas el siguiente día hábil.',
        rule4: 'En casos urgentes, póngase en contacto con el Servicio de Atención al Cliente.',
        newOrderTitle: 'Nuevo pedido',
        labelProductInformation: 'Información del producto',
        labelProduct: 'Producto',
        labelTotalVolume: 'VOLUMEN TOTAL',
        labelOrder: 'PEDIDOS',
        labelSelect: 'Seleccionar',
        labelOrderDetail: 'Detalles del pedido',
        labelDeliveryPlace: 'Lugar de entrega *',
        labelFreight: 'Flete *',
        labelPaymentConditions: 'Condiciones de pago *',
        labelBulkOrder: 'Generar pedidos al por mayor',
        labelBulkOrderEditCIF: 'Importar entregas massiva',
        labelBulkOrderEditFOB: 'Importar carga massiva',
        labelChangesInformationCIF: '*Cualquier cambio en la información anterior puede causar cambios en las entregas ya agregadas.',
        labelChangesInformationFOB: '*Cualquier cambio en la información anterior puede causar cambios en los envíos ya agregados',
        labelRequiredFields: '* Campos obligatorios',
        labelRequiredFieldsValidate: 'Verifique los campos requeridos',
        labelSendOrder: 'Enviar Pedido',
        labelAdd: 'Agregar',
        labelLoad: 'carga',
        labelDelivery: 'entrega',
        labelLoadResume: 'Carga en',
        labelDeliveryResume: 'Entrega en',
        labelImportFileOrderIntent: 'Importar pedidos al por mayor',
        labelCancel: 'Cancelar',
        labelImport: 'Importar',
        labelChooseFile: 'Seleccione Archivo',
        labelFileSelected: 'Archivo seleccionado',
        labelTextModelPart1: 'Puede importar varias fechas de carga utilizando una hoja de cálculo. Descarga la plantilla',
        labelTextModelPart2: 'o',
        labelTextModelPart3: 'para enviar correctamente',
        labelCIFFile: 'Unipar entrega (CIF)',
        labelFOBFile: 'Cliente retira (FOB)',
        labelSFRFile: 'Envío gratis (SFR)',
        labelIncotermDateFOB: 'Fecha de Carga',
        labelIncotermDateCIF: 'Fecha de Entrega',
        labelIncotermDateEditCIF: 'Fecha de Entrega',
        labelCPTFile: 'Unipar entrega (CPT)',
        labelFCAFile: 'Cliente retira (FCA)',
        labelTextModelARPart1: 'Puede importar varias fechas de carga utilizando una hoja de cálculo. Descarga la ',
        labelTextModelARPart2: 'plantilla',
        labelTextModelARPart3: 'para enviar correctamente',
        labelVehicleType: 'Tipo de vehiculo',
        labelDeposit: 'Deposito',
        labelVehiclesQty: 'Cant. de vehículos',
        labelTotalQty: 'Cant. Total',
        labelDeliveryText: 'Entrega',
        labelQuantityWeight: 'Cantidade (ton) *',
        labelVehicle: 'Vehiculo',
        labelDelete: 'Borrar',
        labelShippingCompany: 'Compañía de envios',
        labelOrderNumber: 'Su número de pedido',
        labelMaterialNumber: 'Numero de material',
        labelOrdemItem: 'Artículo pedido',
        labelModalHeaderTextValidDate: '¡Fecha seleccionada no válida!',
        labelModalHeaderTextValidQuantity:'Cant. seleccionada no válida!',
        labelInformationDANFE: 'Campo no obligatorio, solo ingresa esta información en DANFE si es necesario',
        returnValidEqualDate: '¡No es posible programar entregas para el mismo día de la solicitud!',
        returnValidPassedDate: '¡No es posible agendar entregas para los días pasados!',
        labelModalHeaderFileImport: 'Importar archivo',
        labelModalFileImportButton: 'Entendí',
        returnValidEndofWeek: '¡No es posible programar envíos de materiales de PVC los fines de semana!',
        returnValidAfter17Hour: '¡Las solicitudes enviadas después de las 5 p. m. o los fines de semana para entrega el siguiente día hábil se analizarán el siguiente día hábil!',
        returnValidAfter11Hour: 'No es posible programar envíos para el siguiente día hábil después de las 11 am, en este caso comuníquese con Unipar.',
        returnValidAfter11FridayHour: '¡No es posible programar entregas para el fin de semana después de las 11 a.m. del viernes!',
        returnErrorSelectedProduct: 'Seleccione um producto!',
        returnErrorSelectedMethodDelivery: 'Seleccione un método de entrega!',
        returnOKImportFile: 'Archivo importado exitosamente!',
        returnErrorExtensionFiles: 'Sólo es posible importar archivos con extensión .csv, .xls y .xlsx',
        labelSelectedReceiver: 'Por favor seleccione un destinatario',
        labelSelectedIncoterm: 'Por favor seleccione un tipo de envío',
        labelPaymentCondition: 'Por favor seleccione un término de pago',
        labelMessageDeliveryDate: 'Por favor agregue una fecha para su entrega',
        labelReturnOkOrder: 'Solicitud enviada exitosamente!',
        labelComingSoon: 'Volveremos pronto',
        labelAccessArea: 'Para acompañarte accede a la zona',
        labelMyOrders: 'mis pedidos',
        labelForMyOrders: 'Ve para mis pedidos',
        labelErrorMessageOrder: 'Se produjo un error al solicitar el pedido',
        labelImportFile: 'Importación de pedidos',
        labelErrorImportFile: 'Se produjeron los siguientes errores al importar el archivo:',
        labelMultipleQuantityError: 'La cantidad reportada no es múltiplo del producto',
        labelMultipleQuantityErrorDetail: 'La cantidad [informedQuantity] no es múltipla de [multipleWeight]',
        labelQtyInTruck: 'Cant. en el camión',
        labelOrderNotFound: 'We were unable to view the selected order, please try again.',
        labelBack: 'Volver',
        labelErrorFindOrder: 'Se produjo un error al consultar el pedido',
        labelFileModel: 'Archivo de plantilla',
        labelOrderItem: 'Encargar artículos',
        labelQtyResume: 'Cantidade',
        labelAddObservation: 'Agregar observaciones',
        labelEditOrder: 'Editar Pedido',
        labelFinalizeOrder: 'Finalizar Pedido',
        labelErrorDateOutOfLimit: 'No es posible editar el pedido, ya que ya pasaron las 11 am y el pedido es para el siguiente día hábil.',
        labelErrorDatePassed: 'No es posible editar el pedido ya que su fecha ha pasado.',
        labelErrorDateEqual: 'No es posible editar el pedido, ya que la fecha del pedido es la misma que hoy.',
        labelSaveChanges: 'Guardar ediciones',
        labelConfirmAlterOrder: '¡Solicitud de cambio enviada exitosamente!',
        labelErrorAlterOrder: 'Ocorreu um erro ao alterar o pedido',
        labelObservation: 'Observaciones',
          titleErrorOnSap: 'No podemos procesar algunos pedidos.',
          errorOnSapLineOne: 'Lamentablemente no pudimos procesar algunas de sus solicitudes. ¿Quieres intentar comprar pedidos no procesados ​​nuevamente?',
          errorOnSapLineTwo: 'Si el error persiste, comuníquese con el equipo de soporte.',
          materialsWithError: 'No se pueden cambiar materiales:',
          editErrorOnSapLineOne: 'Lamentablemente no pudimos procesar algunas de sus solicitudes. ¿Quieres intentar actualizar los pedidos no procesados ​​nuevamente?'
      },
      notifications: 'Notificaciones',
      footer: {
        labelPrivacyPolicies: "Políticas de Privacidad"
      },
      aproveChangesScreen: {
        tdShipmentDate: 'ENTREGA/COCHE. EN',
        tdShipmentQuantity: 'Cantidad',
        tdVeichleTypeName: 'Nombre del vehículo',
        tdTransporterName: 'Nombre de la compañía',
        tdOrderNumber: 'Número de orden',
        tdMaterialDescription: 'Item de orden',
        tdSelect: 'Seleccionar',
        labelAproveOrder: 'Aprobar órdenes de cambio',
        labelOrderNumber: 'Su número de orden',
        labelMaterialCode: 'Número material',
        labelPaymentConditions: 'Condiciones de pago',
        labelMaterialDescription: 'Descripción del material',
        labelPendenciesAprove: 'Aprobaciones pendientes',
        labelAllAprove: 'Aprobar todo',
        labelNotFound: 'No se encontraron resultados',
        labelDelivery: 'Entrega',
        labelQuantity: 'Quantidade',
        labelVehicle: 'Vehículo',
        labelTransporter: 'Transportador',
        labelOrderItem: 'Item de orden',
        labelModify: 'Modificado en',
        labelObs: 'Observação',
        labelBack: 'Volver',
        labelSave: 'Registrar',
        labelMsgSave: '¡El cambio se guardó correctamente!',
        labelComingSoon: 'Volveremos pronto',
        labelAccessArea: 'Para seguir accede a la zona',
        labelMyOrders: 'Mis pedidos',
        labelGotoMyOrders: 'ir a mis pedidos',
        labelErrorSave: 'Se produjo un error al guardar los cambios.',
        labelOK: 'Entendí',
        labelTryAgain: '¡Inténtalo de nuevo!'
      },
      customerSurvey: {
        step1Title: 'Tu opinión es muy importante para mejorar nuestro Portal, ¡después de todo fue hecho para ti!',
        step2Title: 'Tu opinión es muy importante para mejorar nuestro Portal, ¡después de todo fue hecho para ti!',
        step3Title: 'Tu opinión es muy importante para mejorar nuestro Portal, ¡después de todo fue hecho para ti!',
        step4Title: '¡Gracias por tu valoración!',
        step1Description: 'En una escala del 1 al 5, ¿cómo calificarías tu experiencia utilizando nuestra plataforma?',
        step2Description: 'En una escala del 1 al 5, ¿cuánto recomendaría nuestro Portal a un amigo o colega?',
        step3Description: '¿Te gustaría dejar algún comentario o sugerencia para ayudarnos a mejorar nuestra plataforma?',
        step4Description: 'Su opinión nos ayuda a comprender cómo podemos mejorar nuestra plataforma. ¡Sus comentarios son extremadamente valiosos para nosotros!',
        step3Comments: 'Escribe tu comentario aqui',
        terrible: 'Muy Malo',
        bad: 'Malo',
        regular: 'Regular',
        good: 'Bueno',
        excellent: 'Muy Bueno',
        close: 'Cerrar',
        evalLater: 'Evaluar más tarde',
        eval: 'Evaluar'
      },
      mailSettings: {
        mailSettings: 'Administrar notificaciones por correo electrónico',
        whatNotification: '¿Qué notificaciones por correo electrónico le gustaría recibir?',
        disableNotification: 'Activar todas las notificaciones',
        schedule: 'Orden confirmada',
        departure: 'Vehículo llegó al patio',
        invoiced: 'Orden cargada',
        save: 'Guardar',
        manage: 'Administrar notificaciones',
        sucess: 'Guardado exitosamente.',
        error: 'Ocurrio un error, inténtalo de nuevo'
      },
      callCenter: {
        menu: 'Habla Con Nosotros',
        newTicket: 'Nuevo Atendimento',
        viewTicket: 'Mi petición',
        sector: 'Sector',
        number: 'Servicio No.',
        ticketLevelAll: 'Todos',
        pending: 'Pendiente Unipar',
        served: 'Servido',
        finished: 'Finalizado',
        newPageTitle: 'Envía tu solicitud',
        viewHistoryPageTitle: 'Historial de servicio',
        viewDetailsPageTitle: 'Servicio detalles',
        labelSubject: 'Sujeto',
        labelSubjectSelect: 'Seleccione el sujeto',
        labelPhone: 'Teléfono',
        labelPhonePlaceholder: 'Ingrese el número de contacto',
        labelDescription: 'Mensaje',
        labelDescriptionPlaceholder: 'Ingrese texto',
        attachmentsCustomer: 'Archivos adjuntos Cliente',
        attachmentsUnipar: 'Archivos adjuntos Unipar',
        userMessage: 'Mensage enviada',
        uniparReply: 'Respuesta Unipar',
        servedWarningMessage: 'Si no hay ninguna interacción nueva en este servicio dentro de {{daysToFinishTicket}} día, estará finalizado.',
        successMessage: 'Mensaje enviado correctamente',
        errorMessage: 'Error al enviar mensaje',
        finishedConfirmMessage: '¿Confirma la finalización del servicio?',
        finishedMessage: 'Servicio finalizado',
        addReply: 'Agregar respuesta',
        finishTicket: 'Finalizar servicio',
        errorFinishMessage: 'Error al finalizar el servicio'
      },
      usefulInformation: {
        usefulInformation: 'Informaciones utiles',
        bankingData: 'Datos bancarios',
        registrantionInformation: 'Información de Registro',
        bankDetail: 'Detalles del banco',
        bank: 'Banco',
        agency: 'Agencia',
        checkingAccount: 'Cuenta',
        pixKey: 'Pix'
      },
      usefulInfo: 'Informaciones utiles',
      bulkEdit:{
        editOrders: 'Editar Pedidos',
        portalUniparNr: 'Nro Portal Unipar',
        date: 'Fecha',
        editSuccess: 'Pedidos alterados con éxito.',
        invalidQuantity: 'Cantidad de pedido no válida',
        checkRedFields: 'Revisa los campos en rojo',
        validationAlert: 'Los artículos destacados en color rojo tienen como peso divergente la capacidad del vehículo.',
        alertUnderWeight: 'Peso total do pedido ( @totalOrderWeight t) abaixo mínimo do veículo ( @vehicleMinValue t).',
        alertOverWeight: 'El peso total del pedido (@totalOrderWeight t) superó el límite del vehículo (@vehicleMaxValue t).',
      },
        messageErrorQuantity: '¡Revise las cantidades del pedido con el camión indicado en rojo!',
        totalInVehicle: 'Total en vehículo',
        maxWeight: 'Peso máximo',
        minWeight: 'Peso mínimo',
        multiple: 'Múltiple',
        defaultErrorTitle: 'No fue posible procesar su solicitud',
        defaultErrorBody: 'Favor entrar en contacto con atencionalcliente@unipar.com.'
    }
  }
}